import { useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Navbar, NavbarToggler } from "reactstrap";
import { Nav, Collapse } from "reactstrap";

import logoDpr from "assets/img/logo-topbar.png";

import "./Topbar.scss";

const Topbar = () => {
  const { pathname } = useLocation();

  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <>
      <section className="container-fluid sdg-navbar d-none d-md-flex">
        <div className="sdg-navbar__logo">
          <Link to="/">
            <img src={logoDpr} alt="Logo DPR RI" />
          </Link>
        </div>
        <div className="sdg-navbar__menu">
          <div className="sdg-navbar__menu-list">
            <NavLink
              activeClassName="sdg-navbar__menu-list--active"
              exact
              to="/"
            >
              BERANDA
            </NavLink>
          </div>
          <div className="sdg-navbar__menu-list">
            <NavLink
              activeClassName="sdg-navbar__menu-list--active"
              exact
              to="/tentang-kami?language=INA"
            >
              SDGs DPR
            </NavLink>
          </div>
          <div className="sdg-navbar__menu-list">
            <NavLink
              activeClassName="sdg-navbar__menu-list--active"
              exact
              to="/dashboard"
            >
              DASHBOARD
            </NavLink>
          </div>
          <div className="sdg-navbar__menu-list">
            <NavLink
              activeClassName="sdg-navbar__menu-list--active"
              exact
              to="/knowledge-hub"
              isActive={() => pathname.includes("knowledge-hub")}
            >
              KNOWLEDGE HUB
            </NavLink>
          </div>
          <div className="sdg-navbar__menu-list">
            <NavLink
              activeClassName="sdg-navbar__menu-list--active"
              exact
              to="/masukan"
            >
              MASUKAN
            </NavLink>
          </div>
          <div className="sdg-navbar__menu-list">
            <NavLink
              activeClassName="sdg-navbar__menu-list--active"
              exact
              to="/faq"
            >
              FAQ
            </NavLink>
          </div>
        </div>
      </section>
      <Navbar dark className="d-md-none sdg-navbar">
        <div className="sdg-navbar__logo">
          <Link className="navbar-brand" to="/">
            <img src={logoDpr} alt="Logo DPR RI" />
          </Link>
        </div>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={!collapsed} navbar>
          <Nav className="mr-auto sdg-navbar__menu" navbar>
            <div className="sdg-navbar__menu-list">
              <NavLink
                activeClassName="sdg-navbar__menu-list--active"
                exact
                to="/"
              >
                BERANDA
              </NavLink>
            </div>
            <div className="sdg-navbar__menu-list">
              <NavLink
                activeClassName="sdg-navbar__menu-list--active"
                exact
                to="/tentang-kami?language=INA"
              >
                SDGs DPR
              </NavLink>
            </div>
            <div className="sdg-navbar__menu-list">
              <NavLink
                activeClassName="sdg-navbar__menu-list--active"
                exact
                to="/dashboard"
              >
                DASHBOARD
              </NavLink>
            </div>
            <div className="sdg-navbar__menu-list">
              <NavLink
                activeClassName="sdg-navbar__menu-list--active"
                exact
                to="/knowledge-hub"
                isActive={() => pathname.includes("knowledge-hub")}
              >
                KNOWLEDGE HUB
              </NavLink>
            </div>
            <div className="sdg-navbar__menu-list">
              <NavLink
                activeClassName="sdg-navbar__menu-list--active"
                exact
                to="/masukan"
              >
                MASUKAN
              </NavLink>
            </div>
            <div className="sdg-navbar__menu-list">
              <NavLink
                activeClassName="sdg-navbar__menu-list--active"
                exact
                to="/faq"
              >
                FAQ
              </NavLink>
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default Topbar;
