import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { fetchArticleById } from "utils/api/ArtikelAPI";

import { Footer, Topbar } from "components/layout";
import SubHeaderTitle from "components/SubHeaderTitle";
import { ArticleDetailLoader } from "components/skeleton/Article";
import FrontDetailBreadCrumb from "components/FrontDetailBreadCrumb";

import "./ArtikelDetail.scss";

const ArtikelDetail = () => {
  const { articleId } = useParams();

  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { payload } = await fetchArticleById(articleId);
        setArticle(payload.artikel);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    })();
  }, [articleId]);

  return (
    <>
      <Topbar />
      <section className="sdg-artikel-detail container mt-5">
        <div className="sdg-artikel-detail__wrapper mx-auto">
          <FrontDetailBreadCrumb text="Artikel" />
          {isLoading ? (
            <div className="w-75 mx-auto">
              <ArticleDetailLoader className="ml-lg-4" />
            </div>
          ) : (
            article && (
              <>
                <SubHeaderTitle
                  title={article.judul}
                  username={article.creator_username}
                  date={article.created_at}
                />
                <div className="sdg-artikel-detail__article-type">
                  {article.indikators.map((data, index) => (
                    <img
                      key={index}
                      src={`/assets/img/sdg-program/${data}.svg`}
                      alt={data}
                    />
                  ))}
                </div>
                <img
                  src={article.file_dir}
                  alt="Article"
                  className="sdg-artikel-detail__image"
                />
                <div
                  className="sdg-artikel-detail__text"
                  dangerouslySetInnerHTML={{ __html: article.body }}
                />
              </>
            )
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ArtikelDetail;
