import { useState } from "react";
import { Card, CardBody, CardTitle, Spinner, Label } from "reactstrap";
import { Form, FormGroup, Input, Button, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import { localStorageHelpers } from "utils/helpers";
import { login } from "utils/api/LoginAPI";

import sdImg from "assets/img/sd-img.png";
import logo from "assets/img/logo-dpr-ri.png";

import "./Login.scss";

const Login = () => {
  let history = useHistory();
  const cookiesTime = new Date(new Date().getTime() + 2 * 3600 * 1000);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const onDismiss = () => setIsError(false);
  const handleUsername = (e) => setUsername(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const result = await login(username, password);
      if (result.status === 200) {
        localStorageHelpers.setUserName(username);
        const { payload } = result.data;
        Cookies.set("_SDG_TOKEN", payload.token, { expires: cookiesTime });
        setTimeout(() => {
          setIsError(false);
          setIsLoading(false);
          history.push("/manage/artikel-informasi");
        }, 500);
      }
    } catch (err) {
      setIsError(true);
      setAlert({ status: "danger", msg: err.response.data.message });
      setIsLoading(false);
    }
  };
  return (
    <div className="sdg-login">
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5 mb-3 d-flex justify-content-center">
            <img
              src={sdImg}
              alt="Logo SDG"
              className="img-fluid sdg-login__banner"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <Card className="border-0 shadow sdg-login__card">
              <CardBody className="sdg-login__card-body">
                <div className="sdg-login__card-logo">
                  <img src={logo} alt="Logo DPR" />
                </div>
                <CardTitle tag="p" className="text-center">
                  Selamat Datang di Admin
                </CardTitle>
                <CardTitle tag="h4" className="text-center mb-4">
                  Monitoring SDGs
                </CardTitle>
                {!isLoading && isError && (
                  <Alert
                    color={alert.status}
                    isOpen={isError}
                    toggle={onDismiss}
                  >
                    {alert.msg}
                  </Alert>
                )}
                <Form onSubmit={handleLogin}>
                  <FormGroup className="mb-3">
                    <Label for="username">Username</Label>
                    <Input
                      id="username"
                      type="text"
                      placeholder="Masukkan Username Anda"
                      onChange={handleUsername}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5">
                    <Label for="password">Password</Label>
                    <Input
                      id="password"
                      type="password"
                      placeholder="Masukkan Password Anda"
                      onChange={handlePassword}
                    />
                  </FormGroup>
                  <Button
                    type="submit"
                    color="primary"
                    block={true}
                    className="sdg-login__btn-login"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner color="warning" />}
                    {!isLoading && "Masuk"}
                  </Button>
                </Form>
                <p className="sdg-login__card-copyright">
                  Copyright © 2021 SDGs DPR RI
                </p>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
