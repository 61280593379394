import { useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Spinner, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Select from "react-select";

import { postArticle } from "utils/api/ArtikelAPI";
import { localStorageHelpers } from "utils/helpers";
import { useCheckLogin } from "utils/hooks";

import Manage from "components/Manage";
import UploadBtn from "components/UploadBtn";
import AdminTitle from "components/AdminTitle";

import Placeholder from "assets/img/EmptyImgPlaceholder.png";
import ButtonOval from "components/ButtonAdmin/ButtonOval";

import "./AddNewArticle.scss";

const editorConfig = {
  toolbar: {
    items: [
      "|",
      "bold",
      "italic",
      "|",
      "alignment",
      "outdent",
      "indent",
      "|",
      "link",
      "imageInsert",
      "imageUpload",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "undo",
      "redo",
      "findAndReplace",
      "-",
      "underline",
      "strikethrough",
      "subscript",
      "superscript",
      "fontColor",
      "fontSize",
      "fontBackgroundColor",
      "fontFamily",
      "pageBreak",
      "removeFormat",
      "heading",
    ],
    shouldNotGroupWhenFull: true,
  },
  language: "id",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
      "linkImage",
    ],
  },
};

const selectOptions = [
  { value: "1", label: "Tanpa Kemiskinan" },
  { value: "2", label: "Tanpa Kelaparan" },
  { value: "3", label: "Kehidupan Sehat dan Sejahtera" },
  { value: "4", label: "Pendidikan Berkualitas" },
  { value: "5", label: "Kesetaraan Jender" },
  { value: "6", label: "Air Bersih dan Sanitasi Layak" },
  { value: "7", label: "Energi Bersih dan Terjangkau" },
  { value: "8", label: "Pekerjaan Layak dan Pertumbuhan Ekonomi" },
  { value: "9", label: "Industri Inovasi dan Infrastruktur" },
  { value: "10", label: "Berkurangnya Kesenjangan" },
  { value: "11", label: "Kota dan Pemukiman yang Berkelanjutan" },
  { value: "12", label: "Konsumsi dan Produksi yang Bertanggung Jawab" },
  { value: "13", label: "Penanganan Perubahan Iklim" },
  { value: "14", label: "Ekosistem Lautan" },
  { value: "15", label: "Ekosistem Daratan" },
  { value: "16", label: "Perdamaian Keadilan dan Kelembagaan yang Tangguh" },
  { value: "17", label: "Kemitraan untuk Mencapai Tujuan" },
];

const AddNewArticle = ({ onAdd }) => {
  const { goBack, replace } = useHistory();
  useCheckLogin();
  const userId = localStorageHelpers.getUserId();

  const [uploadedImg, setUploadedImg] = useState("");
  const [displayedImgPreview, setDisplayedImgPreview] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fileUploadRef = useRef();

  const handleUploadTrigger = () => {
    fileUploadRef.current.click();
  };
  const handleFileUpload = (e) => {
    setUploadedImg(e.target.files[0]);
    setDisplayedImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleArticleSubmit = async () => {
    setIsLoading(true);
    const indicatorValues = tags.map((tag) => tag.value).toString();
    const articleData = new FormData();
    articleData.append("judul", title);
    articleData.append("body", description);
    articleData.append("creator_id", userId);
    articleData.append("file", uploadedImg);
    articleData.append("indikators", indicatorValues);

    try {
      await postArticle(articleData);

      onAdd();
      setIsLoading(false);
      replace("/manage/artikel-informasi");
    } catch (err) {
      setIsLoading(false);
      setError(err.response.data.message);
    }
  };

  return (
    <>
      <AdminTitle>Tambah Berita & Informasi</AdminTitle>
      <Manage>
        {error && (
          <Alert color="danger" toggle={() => setError("")}>
            {error}
          </Alert>
        )}
        <Manage.Body>
          <Form>
            <FormGroup row className="mx-1 mb-4">
              <div className="">
                <img
                  src={displayedImgPreview ? displayedImgPreview : Placeholder}
                  alt="artikel"
                  className="sdg-admin-add-article__image"
                />
              </div>
              <div className="px-4">
                <div className="d-flex align-items-center">
                  <span className="mr-4 sdg-admin-manage__text--medium">
                    Photo
                  </span>
                  <UploadBtn onClick={handleUploadTrigger} />
                  <input
                    type="file"
                    ref={fileUploadRef}
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="mt-3">
                  <span className="sdg-admin-manage__helper-text">
                    *Upload foto dengan ukuran kurang lebih 826 x 325 atau yang
                    mendekati ukuran tersebut
                  </span>
                  <span className="sdg-admin-manage__helper-text">
                    *maximal ukuran foto adalah 5MB
                  </span>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Title *</Label>
              <Input
                placeholder="Masukkan Judul Pengumuman"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="sdg-admin-manage__input"
              />
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Deskripsi *</Label>
              <CKEditor
                editor={Editor}
                config={editorConfig}
                data={description}
                onChange={(_, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Select
                options={selectOptions}
                isMulti
                value={tags}
                onChange={(value) => setTags(value)}
              />
            </FormGroup>
          </Form>
        </Manage.Body>
        <Manage.Footer>
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a onClick={goBack} className="sdg-admin-btn sdg-admin-btn--cancel">
            Cancel
          </a>
          {isLoading ? (
            <Spinner color="danger" />
          ) : (
            <ButtonOval onClick={handleArticleSubmit}>Save</ButtonOval>
          )}
        </Manage.Footer>
      </Manage>
    </>
  );
};

export default AddNewArticle;
