import { useEffect, useState } from "react";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import moment from "moment";
import { BsPencil } from "react-icons/bs";
import { BiTrashAlt } from "react-icons/bi";
import { Alert } from "reactstrap";

import AdminDataTable from "components/AdminDataTable/AdminDataTable";
import AddNewArticle from "../AddNewArticle/AddNewArticle";
import AdminContentHeader from "components/layout/AdminLayout/AdminContentHeader";
import ViewArticle from "../ViewArticle/ViewArticle";
import EditArticle from "../EditArticle/EditArticle";

import {
  fetchAllArticles,
  fetchArticlesByDate,
  deleteArticle,
} from "utils/api/ArtikelAPI";

import { useCheckLogin } from "utils/hooks";

import { ReactComponent as DetailIcon } from "assets/img/table-detail-icon.svg";

const ArtikelInformasi = () => {
  const { push } = useHistory();
  const { path, url } = useRouteMatch();

  useCheckLogin();

  const [articles, setArticles] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const removeArticle = async (articleId) => {
    setIsLoading(true);
    try {
      await deleteArticle(articleId);
      const filteredArticles = articles.filter(
        (article) => article.id !== articleId
      );

      setArticles(filteredArticles);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.response.data.message);
    }
  };

  const refetchArticles = async () => {
    setIsLoading(true);
    const { payload } = await fetchAllArticles();
    setArticles(payload.artikel);
    setIsLoading(false);
  };

  const columns = [
    {
      key: "id",
      text: "No.",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
      width: "70px",
      cell: (_, index) => index + 1,
    },
    {
      key: "judul",
      text: "Title",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
    },
    {
      key: "created_at",
      text: "Date",
      sortable: true,
      width: "175px",
      TrOnlyClassName: "sdg-datatable__table-head",
      cell: (record) => moment(record.created_at).format("DD MMMM YYYY"),
    },
    {
      key: "action",
      text: "Action",
      width: "145px",
      cell: (record) => {
        return (
          <>
            <div className="sdg-datatable__button-group">
              <button
                className="sdg-datatable__button-detail"
                onClick={() => push(`${url}/view-artikel/${record.id}`)}
              >
                <DetailIcon />
              </button>
              <button
                className="sdg-datatable__button-edit"
                onClick={() => push(`${url}/edit-artikel/${record.id}`)}
              >
                <BsPencil color="#30D569" size="20" />
              </button>
              <button
                className="sdg-datatable__button-delete"
                onClick={() =>
                  window.confirm("Are you sure?") && removeArticle(record.id)
                }
              >
                <BiTrashAlt color="#FD5457" size="20" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { payload } = await fetchAllArticles();
        setArticles(payload.artikel);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const filter = async () => {
    const start = startDate && moment(startDate).format("YYYY-MM-DD");
    const end = startDate && moment(endDate).format("YYYY-MM-DD");

    try {
      setIsLoading(true);
      const { payload } = await fetchArticlesByDate(start, end);
      setArticles(payload.artikel);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Route exact path={path}>
        {error && <Alert color="danger">{error}</Alert>}
        <AdminContentHeader
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          title="DATA ARTIKEL & INFORMASI"
          btnTo={`${url}/add-new-article`}
          btnText="Tambah Artikel & Informasi"
          clickFilter={filter}
          add
        />
        <AdminDataTable columns={columns} data={articles} loading={isLoading} />
      </Route>
      <Route exact path={`${path}/add-new-article`}>
        <AddNewArticle onAdd={refetchArticles} />
      </Route>
      <Route exact path={`${path}/edit-artikel/:articleId`}>
        <EditArticle onUpdate={refetchArticles} />
      </Route>
      <Route exact path={`${path}/view-artikel/:articleId`}>
        <ViewArticle />
      </Route>
    </>
  );
};

export default ArtikelInformasi;
