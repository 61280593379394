import { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

import { fetchUserByUsername } from "utils/api/UserAPI";
import { localStorageHelpers } from "utils/helpers";

import AdminLayout from "components/layout/AdminLayout";
import { Regulasi, Dokumen, AdminManage } from "pages/admin";
import { ArtikelInformasi, Dapil } from "pages/admin";
import { Masukan, KomunikasiPublik } from "pages/admin";
import { Lokalisasi, CommunityOfPractice } from "pages/admin";

const AdminRoutes = () => {
  const [user, setUser] = useState(null);

  const userName = localStorageHelpers.getUserName();

  useEffect(() => {
    const getUserByUsername = async () => {
      const userId = localStorageHelpers.getUserId();
      try {
        const { payload } = await fetchUserByUsername(userName);
        setUser(payload.user_detail);
        !userId && localStorageHelpers.setUserId(payload.user_detail.id);
      } catch (err) {
        console.log(err);
      }
    };
    getUserByUsername();
  }, [userName]);

  return (
    <Switch>
      <Route path="/manage">
        <AdminLayout user={user}>
          <Route path="/manage/artikel-informasi">
            <ArtikelInformasi />
          </Route>
          <Route path="/manage/dokumen">
            <Dokumen />
          </Route>
          <Route path="/manage/regulasi">
            <Regulasi />
          </Route>
          <Route path="/manage/lokalisasi">
            <Lokalisasi />
          </Route>
          <Route path="/manage/community-of-practice">
            <CommunityOfPractice />
          </Route>
          <Route path="/manage/masukan">
            <Masukan />
          </Route>
          <Route path="/manage/komunikasi-publik">
            <KomunikasiPublik />
          </Route>
          <Route path="/manage/daerah-pemilihan">
            <Dapil />
          </Route>
          <Route path="/manage/admin">
            <AdminManage />
          </Route>
        </AdminLayout>
      </Route>
    </Switch>
  );
};

export default AdminRoutes;
