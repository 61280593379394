import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

const useCheckLogin = () => {
  let history = useHistory();

  useEffect(() => {
    if (typeof Cookies.get("_SDG_TOKEN") === "undefined") {
      history.push("/login");
      localStorage.clear();
    }
  }, [history]);

  return null;
};

export default useCheckLogin;
