import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

const Logout = () => {
  let history = useHistory();
  setTimeout(() => {
    Cookies.remove("_SDG_TOKEN");
    history.push("/login");
  }, 500);

  return null;
};

export default Logout;
