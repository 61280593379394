import { Topbar, Footer } from "components/layout";
import HeaderTitle from "components/HeaderTitle";

import Map from "./Map";
import Line from "./Line";
import Bar from "./Bar";

const Dashboard = () => {
  return (
    <>
      <Topbar />
      <HeaderTitle title="DASHBOARD" />
      <section className="container-fluid mt-3 mt-md-5">
        <Map />
        <Bar />
        <Line />
      </section>
      <Footer />
    </>
  );
};

export default Dashboard;
