import "./HeaderTitle.scss";

const HeaderTitle = ({ title, className = null, subtitle = null }) => {
  return (
    <section className={`sdg-header ${className && className}`}>
      <h1 className="sdg-header__title">{title}</h1>
      {subtitle && <h2 className="sdg-header__subtitle">{subtitle}</h2>}
    </section>
  );
};

export default HeaderTitle;
