import { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, FormGroup, Label, Input, Spinner, Alert } from "reactstrap";

import Manage from "components/Manage";
import AdminTitle from "components/AdminTitle/AdminTitle";
import ButtonOval from "components/ButtonAdmin/ButtonOval";

import PdfIcon from "assets/img/pdf-icon.png";
import { CgClose } from "react-icons/cg";

import "./Form.scss";

const Add = (props) => {
  const {
    category,
    isLoading,
    isError,
    setIsError,
    alert,
    onSubmit,
    formatText = "*Format file adalah PDF dan maximal ukuran file adalah 20MB",
    multiple,
    pdf = null,
  } = props;
  const { pathname } = useLocation();

  const [title, setTitle] = useState("");
  const [file, setFile] = useState(multiple ? [] : null);
  const [filePath, setFilePath] = useState("");
  const [description, setDescription] = useState("");
  const fileInputRef = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const onDismiss = () => setIsError(false);

  const handleUpload = (e) => {
    setFilePath(e.target.value);
    if (multiple) {
      setFile([...file, ...e.target.files]);
    } else {
      setFile(e.target.files[0]);
    }
  };

  const deleteFile = (index) => {
    if (file !== []) {
      setFile(file.filter((el, i) => i !== index));
    }
  };

  useEffect(() => {
    setTitle("");
    setDescription("");
    setFile(multiple ? [] : null);
    setFilePath("");
  }, [pathname]);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const { goBack } = useHistory();
  return (
    <>
      <AdminTitle>Tambah {category}</AdminTitle>
      <Manage>
        <Form
          onSubmit={
            multiple
              ? (e) => onSubmit(e, title, description, file)
              : (e) => onSubmit(e, title, file)
          }
        >
          <Manage.Body>
            {!isLoading && isError && alert && (
              <Alert color={alert.status} isOpen={isError} toggle={onDismiss}>
                {alert.msg}
              </Alert>
            )}
            <FormGroup>
              <Label className="sdg-admin-manage__label">Title *</Label>
              <Input
                type="text"
                className="sdg-admin-manage__input"
                placeholder={`Masukkan Judul ${category}`}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            {multiple && (
              <FormGroup>
                <Label className="sdg-admin-manage__label">Deskripsi *</Label>
                <Input
                  type="textarea"
                  className="sdg-admin-manage__input-textarea"
                  placeholder={`Masukkan Deskripsi ${category}`}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label className="sdg-admin-manage__label">
                {multiple
                  ? pdf
                    ? "Unggah Dokumen *"
                    : "Foto Kegiatan *"
                  : "Dokumen *"}
              </Label>
              <div className="sdg-admin-manage__input sdg-admin-manage__input--file form-control mb-1">
                <button
                  type="button"
                  className="sdg-admin-manage__file-btn"
                  onClick={handleButtonClick}
                >
                  File
                </button>
                {multiple ? (
                  <input
                    className="sdg-admin-manage__hidden-file-btn"
                    type="file"
                    onChange={(e) => handleUpload(e)}
                    accept={pdf ? ".pdf" : ".png, .jpg, .jpeg"}
                    value={filePath ? filePath : ""}
                    ref={fileInputRef}
                    multiple
                  />
                ) : (
                  <input
                    className="sdg-admin-manage__hidden-file-btn"
                    type="file"
                    onChange={(e) => handleUpload(e)}
                    value={filePath ? filePath : ""}
                    ref={fileInputRef}
                  />
                )}

                <span className="sdg-admin-manage__upload-text">
                  {multiple ? "Upload File" : file ? file.name : "Upload File"}
                </span>
              </div>
              <span className="sdg-admin-manage__helper-text">
                <p>{formatText}</p>
                {multiple &&
                  file !== [] &&
                  file.map((data, index) => (
                    <div key={index} className="row mb-2">
                      <div className="col-1">
                        {pdf ? (
                          <img
                            src={PdfIcon}
                            alt="sdg-pdf"
                            className="sdg-admin-manage__image-preview"
                          />
                        ) : (
                          <img
                            src={URL.createObjectURL(data)}
                            alt="sdg-img"
                            className="sdg-admin-manage__image-preview"
                          />
                        )}
                      </div>
                      <div className="col-2">
                        <span className="sdg-admin-manage__image-detail">
                          {`${file[index].name}\n${formatBytes(
                            file[index].size,
                            2
                          )}`}
                        </span>
                      </div>
                      <div className="col-1">
                        <CgClose
                          onClick={() => deleteFile(index)}
                          className="sdg-admin-manage__close-button"
                        />
                      </div>
                    </div>
                  ))}
              </span>
            </FormGroup>
          </Manage.Body>
          <Manage.Footer>
            {/* <Prompt
              message="Are you sure you want to leave?"
              when={!!title || !!filePath}
            /> */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="sdg-admin-btn sdg-admin-btn--cancel" onClick={goBack}>
              Cancel
            </a>
            <ButtonOval>
              {isLoading && <Spinner color="warning" />}
              {!isLoading && "Save"}
            </ButtonOval>
          </Manage.Footer>
        </Form>
      </Manage>
    </>
  );
};

export default Add;
