import { Switch, Route, Link } from "react-router-dom";

import { Home, TentangKami, Dashboard } from "pages/front/";
import { KnowledgeHub, Masukan, Faq, Logout } from "pages/front/";
import { Artikel, ArtikelDetail, Login } from "pages/front/";
import { CommunityOfPracticeDetail, LokalisasiDetail } from "pages/front";

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/tentang-kami">
        <TentangKami />
      </Route>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/knowledge-hub">
        <KnowledgeHub />
      </Route>
      <Route exact path="/masukan">
        <Masukan />
      </Route>
      <Route exact path="/faq">
        <Faq />
      </Route>
      <Route path="/artikel/:articleId">
        <ArtikelDetail />
      </Route>
      <Route path="/artikel">
        <Artikel />
      </Route>
      <Route path="/lokalisasi/:id">
        <LokalisasiDetail />
      </Route>
      <Route exact path="/community-of-practice">
        Community Of Practice {/**Replace with your Component */}
        <Link to="community-of-practice/1">CoP 1</Link>
      </Route>
      <Route path="/community-of-practice/:commId">
        <CommunityOfPracticeDetail />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/logout">
        <Logout />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
