import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Form, FormGroup, Label, Input, Spinner, Alert } from "reactstrap";
import AdminTitle from "components/AdminTitle";
import { ButtonOval } from "components/ButtonAdmin";
import Manage from "components/Manage";

const AddDapil = ({ handleFormAdd, isLoading, isError, setIsError, alert }) => {
  const { goBack } = useHistory();
  const [provinsi, setProvinsi] = useState(null);

  const onDismiss = () => setIsError(false);
  const handleProvinsi = (e) => {
    e.preventDefault();
    setProvinsi(e.target.value);
  };

  return (
    <>
      <AdminTitle>Tambah Daerah Pemilihan</AdminTitle>
      <Manage>
        <Form onSubmit={(e) => handleFormAdd(e, provinsi)}>
          <Manage.Body>
            <FormGroup>
              <Label for="daerah-pemilihan" className="sdg-admin-manage__label">
                Daerah Pemilihan *
              </Label>
              <Input
                type="text"
                name="daerah-pemilihan"
                id="daerah-pemilihan"
                placeholder="Masukkan Daerah Pemilihan"
                onChange={handleProvinsi}
                required
              />
            </FormGroup>
          </Manage.Body>
          <Manage.Footer>
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a onClick={goBack} className="sdg-admin-btn sdg-admin-btn--cancel">
              Cancel
            </a>
            {isLoading ? (
              <Spinner color="primary" />
            ) : (
              <ButtonOval>Save</ButtonOval>
            )}
          </Manage.Footer>
          {!isLoading && isError && (
            <Alert color={alert.status} isOpen={isError} toggle={onDismiss}>
              {alert.msg}
            </Alert>
          )}
        </Form>
      </Manage>
    </>
  );
};

export default AddDapil;
