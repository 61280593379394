import pdfLogo from "assets/img/pdf-glow.svg";
import { Link } from "react-router-dom";

const KnowledgeHubCardItem = ({
  path = null,
  judul,
  file_dir,
  detail = null,
  isCoP,
  isCoPView,
  filename,
}) => {
  return (
    <div className="col-12 col-md-3 sdg-front-card">
      {!isCoPView ? (
        <img src={pdfLogo} alt="logo" className="sdg-front-card__logo" />
      ) : (
        <div className="mt-5" />
      )}
      <div className="sdg-front-card__text">{isCoP ? filename : judul}</div>
      <div className="sdg-front-card__download-btn">
        {detail ? (
          <Link to={path}>Lihat Selengkapnya</Link>
        ) : (
          <a href={file_dir} target="_blank" rel="noreferrer">
            Download
          </a>
        )}
      </div>
    </div>
  );
};

export default KnowledgeHubCardItem;
