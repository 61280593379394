import ContentLoader from "react-content-loader";

const ArticleLoader = (props) => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={188.25}
    viewBox="0 0 100% 188.25"
    backgroundColor="#132442"
    foregroundColor="#24B9F2"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="30" height="30" />
    <rect x="40" y="0" rx="3" ry="3" width="30" height="30" />
    <rect x="80" y="0" rx="3" ry="3" width="30" height="30" />
    <rect x="0" y="40" rx="3" ry="3" width="125" height="15" />
    <rect x="0" y="65" rx="3" ry="3" width="300" height="5" />
    <rect x="0" y="80" rx="3" ry="3" width="300" height="5" />
    <rect x="0" y="95" rx="3" ry="3" width="200" height="5" />
  </ContentLoader>
);

export default ArticleLoader;
