import { useState } from "react";
import { UncontrolledCollapse, CardBody, Card } from "reactstrap";
import "./FaqAccordion.scss";
const FaqAccordion = () => {
  const [aToggler, setAToggler] = useState(false);
  const [bToggler, setBToggler] = useState(false);
  const [cToggler, setCToggler] = useState(false);
  const [dToggler, setDToggler] = useState(false);
  const [eToggler, setEToggler] = useState(false);
  const [fToggler, setFToggler] = useState(false);
  const [gToggler, setGToggler] = useState(false);
  const [hToggler, setHToggler] = useState(false);
  const [iToggler, setIToggler] = useState(false);
  return (
    <>
      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left"
        id="Atoggler"
        onClick={() => setAToggler(!aToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>Apa yang di maksud SDGs Dashboard ?</span>
          <span
            className={`sdg-faq__accordion-triangle-${
              aToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Atoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            SDGs Dashboard merupakan visualisasi dari capaian indikator SDGs
            Indonesia untuk 17 Tujuannya (Goals), yang sebagian besar merupakan
            indikator pembangunan baik di tingkat nasional maupun daerah.
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      <br />

      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left mt-3"
        id="Btoggler"
        onClick={() => setBToggler(!bToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>Apa Hubungan SDGs Dashboard dengan kinerja DPR RI?</span>
          <span
            className={`sdg-faq__accordion-triangle-${
              bToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Btoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            Dewan Perwakilan Rakyat (DPR RI) memiliki peran untuk mengawasi
            pembiayaan pembangunan nasional di Indonesia melalui perwakilan
            dewan rakyat di setiap dewan pemilihan. Oleh sebab itu, SDGs
            Dashboard berperan untuk melakukan evaluasi dan monitoring
            pencapaian pembangunan berkelanjutan di wilayah dewan pemilihan
            (dapil). Selain melaksanakan peran pengawasan, SDGs Dashboard juga
            dapat berfungsi sebagai konsultasi publik terkait progress
            pelaksanaan masing-masing Tujuan Pembangunan Berkelanjutan.
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      <br />

      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left mt-3"
        id="Ctoggler"
        onClick={() => setCToggler(!cToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>Apa tujuan dan manfaat dari Platform SDGs Dashboard ?</span>
          <span
            className={`sdg-faq__accordion-triangle-${
              cToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Ctoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            Manfaat SDGs Dashboard dapat menjadi evaluasi kebijakan baik bagi
            Perwakilan Rakyat dan Pemerintah Nasional dalam pelaksanaan Tujuan
            Pembangunan Berkelanjutan 2030 di Indonesia
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      <br />

      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left mt-3"
        id="Dtoggler"
        onClick={() => setDToggler(!dToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>
            Bagaimana saya bisa mengakses regulasi mengenai aturan – aturan
            perundang-undangan terkait Agenda Pembangunan Berkelanjutan di
            Indonesia?
          </span>
          <span
            className={`sdg-faq__accordion-triangle-${
              dToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Dtoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            Pertama, silahkan akses Portal Monitoring SDGs DPR RI melalui laman
            sdgs.dpr.go.id. Kemudian pilih menu Knowledge Hub pada bagian atas
            halaman. Pilih menu Regulasi, silahkan cari regulasi yang ingin Anda
            cari.
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      <br />

      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left mt-3"
        id="Etoggler"
        onClick={() => setEToggler(!eToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>
            Bagaimana saya bisa mengakses indikator keberhasilan Agenda Tujuan
            Pembangunan Berkelanjutan di daerah Pemilihan?
          </span>
          <span
            className={`sdg-faq__accordion-triangle-${
              eToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Etoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            Untuk mengakses indikator keberhasilan Agenda Tujuan Pembangunan
            Berkelanjutan di daerah Pemilihan silahkan kunjungi Portal
            Monitoring SDGs DPR RI melalui laman sdgs.dpr.go.id. Kemudian pilih
            menu Dashboard pada bagian atas halaman. Dalam halaman tersebut akan
            tertampil berbagai dashboard yang dapat diakses mengenai indikator
            keberhasilan Agenda Tujuan Pembangunan Berkelanjutan di daerah
            pemilihan (dapil).
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      <br />

      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left mt-3"
        id="Ftoggler"
        onClick={() => setFToggler(!fToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>
            Bagaimana saya bisa mengakses kegiatan parlemen terkait pembangunan
            berkelanjutan?
          </span>
          <span
            className={`sdg-faq__accordion-triangle-${
              fToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Ftoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            Untuk mengakses berbagai kegiatan parlemen terkait Tujuan
            Pembangunan Berkelanjutan, silahkan kunjungi Portal Monitoring SDGs
            DPR RI melalui laman sdgs.dpr.go.id. Kemudian scroll kebawah pada
            menu Artikel dan Informasi. Anda bisa memilih Lihat Selengkapnya
            untuk melihat kumpulan artikel dan informasi terkait dengan kegiatan
            parlemen.
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      <br />

      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left mt-3"
        id="Gtoggler"
        onClick={() => setGToggler(!gToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>
            Bagaimana saya bisa mengakses informasi mengenai kegiatan dan
            kinerja PANJA SDGs?
          </span>
          <span
            className={`sdg-faq__accordion-triangle-${
              gToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Gtoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            Untuk mengakses berbagai kegiatan dan kinerja PANJA SDGs, silahkan
            kunjungi Portal Monitoring SDGs DPR RI melalui laman sdgs.dpr.go.id.
            Kemudian scroll kebawah pada menu Artikel dan Informasi. Anda bisa
            memilih Lihat Selengkapnya untuk melihat kumpulan artikel dan
            informasi terkait dengan kegiatan parlemen.
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      <br />

      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left mt-3"
        id="Htoggler"
        onClick={() => setHToggler(!hToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>Bagaimana saya bisa mengisikan form masukan?</span>
          <span
            className={`sdg-faq__accordion-triangle-${
              hToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Htoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            Pertama, silahkan akses Portal Monitoring SDGs DPR RI melalui laman
            sdgs.dpr.go.id. Kemudian pilih menu Masukan pada bagian atas
            halaman. Silahkan pilih indikator yang ingin Anda beri masukan
            dengan memilih salah satu gambar. Setelah itu, silahkan isikan
            masukan pada form yang tersedia terdiri dari Nama Lengkap, Email,
            Nomor Handphone, Dapil dan Masukan yang Anda ingin berikan.
          </CardBody>
        </Card>
      </UncontrolledCollapse>

      <br />

      <button
        className="sdg-faq__accordion-toggler pl-4 py-3 text-left mt-3"
        id="Itoggler"
        onClick={() => setIToggler(!iToggler)}
      >
        <div className="d-flex justify-content-between">
          <span>
            Bagaimana saya bisa terlibat dan berkolaborasi dengan DPR RI terkait
            Agenda Tujuan Pembangunan Berkelanjutan?
          </span>
          <span
            className={`sdg-faq__accordion-triangle-${
              iToggler ? `up` : `down`
            } mr-4 ml-3 my-auto`}
          ></span>
        </div>
      </button>
      <UncontrolledCollapse toggler="#Itoggler">
        <Card style={{ backgroundColor: "#192B4A", borderColor: "#192B4A" }}>
          <CardBody className="sdg-faq__accordion-text">
            Untuk terlibat dan berkolaborasi dengan DPR RI terkait Agenda Tujuan
            Pembangunan Berkelanjutan, silahkan kunjungi Komunikasi Publik di
            menu FAQ. Badan Kerja Sama Antar Parlemen (BKSAP) - DPR RI membuka
            kesempatan untuk konsultasi Publik secara langsung di Kantor DPR RI.
            Silahkan mengisi formulir yang ada untuk menjadwalkan pertemuan
            dengan Staff Sekretariat SDGs DPR RI yang terdiri dari Nama Lengkap,
            Email, Keperluan, Nomor Telpon, Subjek dan Pesan yang ingin
            disampaikan.
          </CardBody>
        </Card>
      </UncontrolledCollapse>
    </>
  );
};

export default FaqAccordion;
