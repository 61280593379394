import { Topbar, Footer } from "components/layout";
import HeaderTitle from "components/HeaderTitle";
import SubHeaderTitle from "components/SubHeaderTitle";
import FaqAccordion from "./FaqAccordion";
import FaqForm from "./FaqForm";

const Faq = () => {
  return (
    <>
      <Topbar />
      <div className="sdg-faq__title container-fluid">
        <HeaderTitle
          title="FREQUENTLY ASKED QUESTIONS"
          className="sdg-header__desktop"
        />
        <HeaderTitle title="FAQ" className="sdg-header__mobile" />
      </div>
      <section className="sdg-faq container my-5">
        <div className="sdg-faq__wrapper mx-auto">
          <FaqAccordion />
          <br />
          <SubHeaderTitle title="KOMUNIKASI PUBLIK" className="mt-5" />
          <section className="sdg-komunikasi my-3">
            <FaqForm />
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Faq;
