import "./ButtonAdmin.scss";

const ButtonOval = ({
  children,
  onClick,
  className: userClassName,
  ...props
}) => {
  return (
    <button
      className={`sdg-button-oval ${userClassName ? userClassName : ""}`}
      onClick={onClick ? onClick : () => {}}
      {...props}
    >
      {children}
    </button>
  );
};

export default ButtonOval;
