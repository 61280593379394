import { useScrollRestore } from "utils/hooks";

import AdminRoutes from "routes/AdminRoutes";
import AppRoutes from "routes/AppRoutes";

function App() {
  useScrollRestore();

  return (
    <div className="App">
      <AppRoutes />
      <AdminRoutes />
    </div>
  );
}

export default App;
