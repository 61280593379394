const ContentBody = ({ component, header, children }) => {
  return (
    <div className="d-flex flex-column align-items-center my-5">
      <div className="">{component}</div>
      <h3 className="mt-lg-2 mt-0 mb-lg-5 mb-4 sdg-tentang-content__title">
        {header}
      </h3>
      {children}
    </div>
  );
};

export default ContentBody;
