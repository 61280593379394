import { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";

import * as DokumenAPI from "utils/api/DokumenAPI";
import AdminContentHeader from "components/layout/AdminLayout/AdminContentHeader";
import AdminDataTable from "components/AdminDataTable/";
import { useCheckLogin } from "utils/hooks";
import { Add, Edit } from "components/forms/KnowledgeHub";

import PdfImage from "assets/img/pdf-icon.svg";

import { AiOutlineEye } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { BiTrashAlt } from "react-icons/bi";

import Data from "./data.json";

const Dokumen = () => {
  let history = useHistory();
  const { path, url } = useRouteMatch();
  useCheckLogin();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [list, setList] = useState(Data);

  const columns = [
    {
      key: "id",
      text: "No.",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
      width: "70px",
      cell: (record, index) => {
        return index + 1;
      },
    },
    {
      key: "judul",
      text: "Title",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
    },
    {
      key: "file",
      text: "File",
      width: "80px",
      cell: () => {
        return <img src={PdfImage} alt="pdf file" />;
      },
    },
    {
      key: "created_at",
      text: "Date",
      sortable: true,
      width: "175px",
      TrOnlyClassName: "sdg-datatable__table-head",
      cell: (record) => {
        return moment(record.created_at).format("DD MMMM YYYY");
      },
    },
    {
      key: "action",
      text: "Action",
      width: "145px",
      cell: (record) => {
        return (
          <>
            <div className="sdg-datatable__button-group">
              <a href={record.file_dir} target="_blank" rel="noreferrer">
                <button className="sdg-datatable__button-detail">
                  <AiOutlineEye color="#165FBB" size="20" />
                </button>
              </a>
              <Link to={`${url}/edit/${record.id}`}>
                <button className="sdg-datatable__button-edit">
                  <BsPencil color="#30D569" size="20" />
                </button>
              </Link>
              <button
                className="sdg-datatable__button-delete"
                onClick={() =>
                  window.confirm("Are you sure?") && deleteList(record.id)
                }
              >
                <BiTrashAlt color="#FD5457" size="20" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setIsLoading(true);

    try {
      const result = await DokumenAPI.read();
      if (result.status === 200) {
        setTimeout(() => {
          setList(result.payload.dokumen_list);
          setIsLoading(false);
        }, 300);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const deleteList = async (id) => {
    setIsLoading(true);

    try {
      const result = await DokumenAPI.remove(id);
      if (result.status === 200) {
        getList();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleFormAdd = async (e, title, file) => {
    e.preventDefault();
    setIsLoading(true);
    setIsError(false);
    setAlert(null);

    const bodyReq = new FormData();
    bodyReq.append("judul", title);
    bodyReq.append("file", file);

    try {
      const result = await DokumenAPI.create(bodyReq);
      if (result.status === 201) {
        setIsLoading(false);
        setIsError(false);
        setAlert({ status: "success", msg: result.message });
        history.push(path);
        getList();
      }
    } catch (err) {
      setIsError(true);
      setAlert({ status: "danger", msg: err.response.data.message });
      setIsLoading(false);
    }
  };

  const handleFormUpdate = async (e, title, file, id) => {
    e.preventDefault();
    setIsLoading(true);
    setIsError(false);
    setAlert(null);

    const bodyReq = new FormData();
    bodyReq.append("id", id);
    bodyReq.append("judul", title);
    bodyReq.append("file", file);

    try {
      const result = await DokumenAPI.update(bodyReq);
      if (result.status === 200) {
        setIsLoading(false);
        setIsError(false);
        setAlert({ status: "success", msg: result.message });
        history.push(path);
        getList();
      }
    } catch (err) {
      setIsError(true);
      setAlert({ status: "danger", msg: err.response.data.message });
      setIsLoading(false);
    }
  };

  const filter = async () => {
    const start = startDate && moment(startDate).format("YYYY-MM-DD");
    const end = startDate && moment(endDate).format("YYYY-MM-DD");

    try {
      setIsLoading(true);
      const result = await DokumenAPI.readByDate(start, end);
      setList(result.payload.dokumen_list);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <Switch>
      <Route exact path={path}>
        <section className="sdg-admin-manage">
          <AdminContentHeader
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
            title="DATA DOKUMEN"
            btnTo={`${url}/add`}
            btnText="Tambah Dokumen"
            clickFilter={filter}
            add
          />
          <AdminDataTable columns={columns} data={list} loading={isLoading} />
        </section>
      </Route>
      <Route path={`${path}/add`}>
        <Add
          category="Dokumen"
          isLoading={isLoading}
          isError={isError}
          setIsError={setIsError}
          alert={alert}
          onSubmit={handleFormAdd}
        />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <Edit
          category="Dokumen"
          isLoading={isLoading}
          isError={isError}
          setIsError={setIsError}
          alert={alert}
          onSubmit={handleFormUpdate}
        />
      </Route>
    </Switch>
  );
};

export default Dokumen;
