import ArticleLoader from "./ArticleLoader";

const HomeArticleLoader = () => {
  return (
    <div className="sdg-article-item__article row w-100">
      <div className="sdg-article-item__article-list col-lg-4 col-12">
        <ArticleLoader />
      </div>
      <div className="sdg-article-item__article-list col-lg-4 col-12">
        <ArticleLoader />
      </div>
      <div className="sdg-article-item__article-list col-lg-4 col-12">
        <ArticleLoader />
      </div>
    </div>
  );
};

export default HomeArticleLoader;
