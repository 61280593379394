import { NavLink, useLocation } from "react-router-dom";

import { useQuery } from "utils/hooks";

import "./LanguageSwitch.scss";

const LanguageSwitch = () => {
  const location = useLocation();

  const currentLanguage = useQuery().get("language");

  return (
    <div className="row justify-content-center w-100 my-lg-4 mt-4 mt-lg-0">
      <div className="px-lg-3 pr-lg-4 pr-2">Pilihan Bahasa</div>
      <div className="px-lg-3 pr-lg-4 px-2">
        <NavLink
          to={`${location.pathname}?language=INA`}
          className="sdg-tentang__language-option"
          isActive={() => currentLanguage === "INA"}
          activeClassName="sdg-tentang__language-option--active"
        >
          Indonesia
        </NavLink>
      </div>
      <div className="px-lg-3 px-2">
        <NavLink
          to={`${location.pathname}?language=EN`}
          className="sdg-tentang__language-option"
          isActive={() => currentLanguage === "EN"}
          activeClassName="sdg-tentang__language-option--active"
        >
          English
        </NavLink>
      </div>
    </div>
  );
};

export default LanguageSwitch;
