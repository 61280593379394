import ContentLoader from "react-content-loader";

const ArticleDetailLoader = (props) => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={721.36}
    viewBox="0 0 100% 721.36"
    backgroundColor="#132442"
    foregroundColor="#24B9F2"
    {...props}
  >
    <rect x="148" y="30" rx="3" ry="3" width="287" height="23" />
    <rect x="165" y="80" rx="3" ry="3" width="251" height="5" />
    <rect x="224" y="100" rx="3" ry="3" width="32" height="32" />
    <rect x="325" y="99" rx="3" ry="3" width="32" height="32" />
    <rect x="275" y="99" rx="3" ry="3" width="32" height="32" />
    <rect x="-18" y="161" rx="3" ry="3" width="608" height="253" />
    <rect x="6" y="436" rx="3" ry="3" width="251" height="5" />
    <rect x="6" y="449" rx="3" ry="3" width="582" height="7" />
    <rect x="5" y="464" rx="3" ry="3" width="582" height="7" />
    <rect x="5" y="480" rx="3" ry="3" width="582" height="7" />
    <rect x="5" y="497" rx="3" ry="3" width="582" height="7" />
  </ContentLoader>
);

export default ArticleDetailLoader;
