const ButtonOutline = ({ children, onClick }) => {
  return (
    <button
      type="button"
      className="sdg-btn-outline"
      onClick={onClick ? onClick : () => {}}
    >
      {children}
    </button>
  );
};

export default ButtonOutline;
