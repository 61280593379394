import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Spinner, Alert } from "reactstrap";
import AdminTitle from "components/AdminTitle";
import { ButtonOval } from "components/ButtonAdmin";
import Manage from "components/Manage";
import "./KomunikasiPublik.scss";

import * as KomunikasiPublikAPI from "utils/api/KomunikasiPublikAPI";
import moment from "moment";

const AddKomunikasiPublik = () => {
  let history = useHistory();
  const { goBack } = useHistory();
  const { id } = useParams();
  const [response, setResponse] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const result = await KomunikasiPublikAPI.retrieveById(id);
      if (result.status === 200) {
        setTimeout(() => {
          setData(result.payload.masukan);
          setIsLoading(false);
        }, 300);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(true);
      // setError(err.response.data.message);
    }
  };

  const handleFormResponse = async (e, id, response) => {
    e.preventDefault();
    setIsLoading(true);

    const dataBaru = { id: id, respon: response };
    try {
      const result = await KomunikasiPublikAPI.createBalasan(dataBaru);
      if (result.status === 200) {
        setIsLoading(false);
        setIsError(false);
        history.push("/manage/komunikasi-publik");
      }
    } catch (err) {
      setIsError(true);
      setAlert({ status: "danger", msg: err.response.data.message });
      setIsLoading(false);
    }
  };

  const onDismiss = () => setIsError(false);

  const handleResponse = (e) => {
    e.preventDefault();
    setResponse(e.target.value);
  };

  return (
    <section className="sdg-admin-komunikasi-publik">
      <AdminTitle>Detail Komunikasi Publik</AdminTitle>
      <Manage>
        <Form onSubmit={(e) => handleFormResponse(e, id, response)}>
          {isLoading && <Spinner color="primary" />}
          {data && !isLoading && (
            <>
              <Manage.Body>
                <div className="row">
                  <div className="col-4">
                    <span className="sdg-admin-komunikasi-publik__label">
                      Nama
                    </span>
                    {data.nama}
                  </div>
                  <div className="col-4">
                    <span className="sdg-admin-komunikasi-publik__label">
                      Nomor Telfon
                    </span>
                    {data.nomor_telepon}
                  </div>
                </div>
                <div className="row my-3 pb-4 sdg-admin-komunikasi-publik__border">
                  <div className="col-4">
                    <span className="sdg-admin-komunikasi-publik__label">
                      Email
                    </span>
                    {data.email}
                  </div>
                  <div className="col-4">
                    <span className="sdg-admin-komunikasi-publik__label">
                      Tanggal
                    </span>
                    {moment(data.created_at).format("dddd, DD MMMM YYYY")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-1 pr-0">
                    <span className="sdg-admin-komunikasi-publik__label-subject">
                      Keperluan
                    </span>
                  </div>
                  <div className="col-11">
                    <span>{data.keperluan}</span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-1 pr-0">
                    <p className="sdg-admin-komunikasi-publik__label-subject ">
                      Subject
                    </p>
                  </div>
                  <div className="col-11">
                    <span className="sdg-admin-komunikasi-publik__label-title">
                      {data.subjek}
                    </span>
                  </div>
                </div>

                <p className="sdg-admin-komunikasi-publik__content mt-2">
                  {data.masukan}
                </p>
                <FormGroup>
                  <Label
                    for="jawaban"
                    className="sdg-admin-komunikasi-publik__label"
                  >
                    Balasan *
                  </Label>
                  <Input
                    type="textarea"
                    name="jawaban"
                    id="jawaban"
                    rows="6"
                    placeholder="Masukkan jawaban Anda"
                    onChange={handleResponse}
                    value={data.balasan}
                    required
                  />
                </FormGroup>
              </Manage.Body>
              <Manage.Footer>
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a
                  onClick={goBack}
                  className="sdg-admin-btn sdg-admin-btn--cancel"
                >
                  Cancel
                </a>
                {isLoading ? (
                  <Spinner color="primary" />
                ) : (
                  <ButtonOval>Save</ButtonOval>
                )}
              </Manage.Footer>
              {!isLoading && isError && (
                <Alert color={alert.status} isOpen={isError} toggle={onDismiss}>
                  {alert.msg}
                </Alert>
              )}
            </>
          )}
        </Form>
      </Manage>
    </section>
  );
};

export default AddKomunikasiPublik;
