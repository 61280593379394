import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Alert, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { AiOutlineEyeInvisible as EyeSlashed } from "react-icons/ai";

import Manage from "components/Manage";
import AdminTitle from "components/AdminTitle";
import ButtonOval from "components/ButtonAdmin/ButtonOval";
import { fetchUserByUsername, updateUser } from "utils/api/UserAPI";
import { checkEmail } from "utils/helpers/";
import { ReactComponent as Eye } from "assets/img/Eye.svg";

const EditAdmin = ({ onAdd }) => {
  const { goBack, replace } = useHistory();
  const { userName } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isValidPassword = () => password === confirmPassword;
  const isValidEmail = () => checkEmail(email);

  const isValid = isValidPassword() && isValidEmail() && username;

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
    isValidEmail();
  };

  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
    isValidPassword();
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);
    const reqBody = {
      username: userName,
      email_new: userEmail === email ? null : email,
      password_new: password ? password : null,
      username_new: userName === username ? null : username,
    };

    try {
      await updateUser(reqBody);
      setIsLoading(false);
      onAdd();
      replace("/manage/admin");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError(err.response.data.message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { payload } = await fetchUserByUsername(userName);
        setUserEmail(payload.user_detail.email);
        setEmail(payload.user_detail.email);
        setUsername(payload.user_detail.username);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [userName]);

  return (
    <>
      <AdminTitle>Edit Admin</AdminTitle>
      <Manage>
        {error && (
          <Alert color="danger" toggle={() => setError(null)}>
            {error}
          </Alert>
        )}
        <Manage.Body>
          <Form>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Email *</Label>
              <Input
                className={`sdg-admin-manage__input ${
                  !isValidEmail() &&
                  isEmailTouched &&
                  "sdg-admin-manage__input--error"
                }`}
                placeholder="Masukkan Email"
                value={email}
                onChange={handleEmailInput}
                onBlur={() => setIsEmailTouched(true)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Username *</Label>
              <Input
                className="sdg-admin-manage__input"
                placeholder="Masukkan Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Password *</Label>
              <Input
                className={`sdg-admin-manage__input sdg-admin-manage__input--password ${
                  !isValidPassword() &&
                  isPasswordTouched &&
                  "sdg-admin-manage__input--error"
                }`}
                placeholder="************"
                value={password}
                onChange={handlePasswordInput}
                onBlur={() => setIsPasswordTouched(true)}
                type={showPassword ? "text" : "password"}
              />
              {showPassword ? (
                <EyeSlashed
                  className="sdg-admin-manage__eye-icon sdg-admin-manage__eye-icon--slashed"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : (
                <Eye
                  className="sdg-admin-manage__eye-icon"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
              <p>
                <small className="text-warning font-weight-lighter font-italic">
                  Kosongkan jika password tidak diganti.
                </small>
              </p>
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">
                Ulangi Password *
              </Label>
              <Input
                className={`sdg-admin-manage__input sdg-admin-manage__input--password ${
                  !isValidPassword() &&
                  isPasswordTouched &&
                  "sdg-admin-manage__input--error"
                }`}
                placeholder="************"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showConfirmPassword ? "text" : "password"}
              />
              {showConfirmPassword ? (
                <EyeSlashed
                  className="sdg-admin-manage__eye-icon sdg-admin-manage__eye-icon--slashed"
                  onClick={() =>
                    setShowConfirmPassword((prevState) => !prevState)
                  }
                />
              ) : (
                <Eye
                  className="sdg-admin-manage__eye-icon"
                  onClick={() =>
                    setShowConfirmPassword((prevState) => !prevState)
                  }
                />
              )}
              <p>
                <small className="text-warning font-weight-lighter font-italic">
                  Kosongkan jika password tidak diganti.
                </small>
              </p>
            </FormGroup>
          </Form>
        </Manage.Body>
        <Manage.Footer>
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a onClick={goBack} className="sdg-admin-btn sdg-admin-btn--cancel">
            Cancel
          </a>
          {isLoading ? (
            <Spinner color="danger" />
          ) : (
            <ButtonOval onClick={handleFormSubmit} disabled={!isValid}>
              Save
            </ButtonOval>
          )}
        </Manage.Footer>
      </Manage>
    </>
  );
};

export default EditAdmin;
