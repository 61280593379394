import { useCheckLogin } from "utils/hooks";

import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";

import "assets/scss/admin/main.scss";
import "./AdminLayout.scss";

const AdminLayout = ({ children, user }) => {
  useCheckLogin();
  return (
    <>
      <AdminHeader user={user} />
      <main className="sdg-admin-container">
        <AdminSidebar />
        <section className="sdg-admin-content">
          <div className="">{children}</div>
          <AdminFooter />
        </section>
      </main>
    </>
  );
};

export default AdminLayout;
