import { Button } from "reactstrap";
import "./ButtonAdmin.scss";

const ButtonDate = ({ children, onClick }) => {
  return (
    <Button onClick={onClick} className="sdg-button-date mr-3">
      {children}
    </Button>
  );
};

export default ButtonDate;
