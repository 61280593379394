import { Link } from "react-router-dom";

import HeaderTitle from "components/HeaderTitle";
import { Footer, Topbar } from "components/layout";
import ContentBody from "./ContentBody";
import LanguageSwitch from "./LanguageSwitch";

import { useQuery } from "utils/hooks";

import { ReactComponent as AboutSectionImg1 } from "assets/img/AboutSectionImg1.svg";
import { ReactComponent as AboutSectionImg2 } from "assets/img/AboutSectionImg2.svg";
import { ReactComponent as AboutSectionImg3 } from "assets/img/AboutSectionImg3.svg";

import "./TentangKami.scss";

const indonesia = [
  {
    id: 1,
    component: <AboutSectionImg1 />,
    header: "SDGs DPR RI",
    content: (
      <>
        <p className="sdg-tentang-content__text">
          Portal Monitoring SDGs DPR RI adalah sebuah portal yang dikembangkan
          untuk DPR RI dan juga masyarakat dalam melakukan monitoring
          perkembangan dan capaian – capaian SDGs berdasarkan daerah pemilihan
          (dapil). Informasi dan data dalam Portal ini dapat digunakan untuk
          memperkaya wawasan, menyampaikan masukan maupun menyelesaikan
          masalah-masalah SDGs sesuai dengan dapil masing-masing.
        </p>
        <p className="sdg-tentang-content__text">
          Portal Monitoring SDGS memudahkan Anggota DPR RI dalam melihat
          pencapaian SDGs secara komprehensif yang dapat digunakan dalam
          perumusan legislasi, pertimbangan pengawasan anggaran dan
          program-program pemerintahan. Portal ini juga memberikan kesempatan
          bagi masyarakat untuk memberikan masukan dan berkolaborasi dengan DPR
          RI untuk memastikan pengawasan program SDGs di daerah.
        </p>
      </>
    ),
  },
  {
    id: 2,
    component: <AboutSectionImg2 />,
    header: "PANJA SDGs DPR",
    content: (
      <>
        <p className="sdg-tentang-content__text">
          Dalam menyukseskan tujuan, target, dan indikator SDGs, BKSAP - yang
          merupakan penjuru diplomasi parlemen—membentuk Panitia Kerja Tujuan
          Pembangunan Berkelanjutan. Pembentukan Panja TPB berlandaskan pada
          dasar hukum UU Nomor 17/2014 tentang MPR, DPR, DPD, DPRD (MD3) dan
          Peraturan DPR RI Nomor 1/ 2014 dan perubahannya tentang Tata Tertib
          DPR. <strong>Panja</strong>Tujuan Pembangunan Berkelanjutan (
          <strong>SDGs</strong>) memiliki dua tujuan: (1) untuk membentuk
          keterlibatan awal parlemen dalam isu-isu Tujuan Pembangunan
          Berkelanjutan serta; (2) untuk membangun kemitraan dengan mitra
          domestik dan juga mitra internasional untuk mensukseskan pelaksanaan{" "}
          <strong>SDGs.</strong>
        </p>
      </>
    ),
  },
  {
    id: 3,
    component: <AboutSectionImg3 />,
    header: "PERAN DPR \nDALAM SDGs",
    content: (
      <>
        <p className="sdg-tentang-content__text my-3">
          DPR RI adalah salah satu aktor utama dalam mendorong dan memastikan
          tercapainya TPB/SDGs di tingkat nasional, khususnya melalui tiga
          fungsi konstitusional yang dimilikinya—penganggaran, legislasi, dan
          pengawasan terhadap kinerja pemerintah selaku pelaksana kebijakan
          nasional terkait TPB/SDGs.
        </p>
        <p className="sdg-tentang-content__text my-3">
          Peran parlemen juga sangat krusial dalam upaya mendorong pencapaian
          TPB/SDGs yang people-centered melalui fungsi representatif, mendorong
          pembangunan yang berpusat pada masyarakat, serta mampu mencerminkan
          dan responsif terhadap kebutuhan konstituennya.
        </p>

        <p className="sdg-tentang-content__text my-3">
          Pada tingkat nasional, Parlemen juga termasuk salah satu pemangku
          kepentingan utama yang diharapkan dapat berpartisipasi aktif dalam
          pelaksanaan dan pencapaian TPB/SDGs di Indonesia, bersama dengan
          stakeholders lain yang meliputi:
          <br />
          1. Pemerintah
          <br />
          2. Akademisi dan Pakar
          <br />
          3. Filantropi dan Pelaku Usaha/Swasta
          <br />
          4. Organisasi Kemasyarakatan dan Media
          <br />
        </p>
        <p className="sdg-tentang-content__text my-3">
          Indonesia juga telah menerapkan prinsip inklusif “No One Left Behind,”
          di mana semua stakeholders memiliki kesetaraan dan berperan aktif
          melalui peran dan kapasitasnya dalam mendukung pencapaian TPB/SDGs
        </p>
        <p className="sdg-tentang-content__text my-3">
          DPR RI sebagai badan legislatif nasional berdasarkan kesepakatan dalam
          Deklarasi Hanoi pada tahun 2015 juga memiliki peran untuk:
        </p>
        <p className="sdg-tentang-content__text my-3">
          1. Mendukung implementasi tujuan–tujuan yang terkandung dalam TPB/SDGs
          melalui penyusunan legislasi yang sinergis dengan indikator-indikator
          yang telah ditetapkan.
          <br />
          2. Mendukung diseminasi dan lokalisasi kebijakan–kebijakan terkait
          TPB/SDGs untuk publik pada tingkat nasional dan daerah.
          <br />
          3. Meminta dan mendorong pertanggungjawaban pemerintah yang telah
          melaksanakan kebijakan-kebijakan pembangunan yang berkaitan dengan
          TPB/SDGs.
        </p>
      </>
    ),
  },
];

const english = [
  {
    id: 1,
    component: <AboutSectionImg1 />,
    header: "DPR SDGs MONITORING PORTAL ",
    content: (
      <>
        <p className="sdg-tentang-content__text">
          The DPR RI SDGs Monitoring Portal is a digital platform developed for
          Indonesia Parliament (DPR RI) and the public in monitoring the
          development and implementation of the SDGs based on electoral
          districts (dapil). The information and data in this Portal can be used
          to bring insights, provide input and resolve SDGs issues in accordance
          with Members of Parliaments (MPs) respective electoral districts.
        </p>
        <p className="sdg-tentang-content__text">
          The SDGs Monitoring Portal will provide a more comprehensive and
          accessible platform for Indonesia MPs to see the achievement and
          implementation of the Goals, which can be used as a foundation for the
          formulation of legislation, budget oversight considerations and
          government programs. This portal also provides an opportunity for the
          community to provide input and collaborate with the DPR RI to support
          monitoring and implementation of SDGs program in the region.
        </p>
      </>
    ),
  },
  {
    id: 2,
    component: <AboutSectionImg2 />,
    header: "DPR RI SDGs WORKING COMMITTEE ",
    content: (
      <>
        <p className="sdg-tentang-content__text">
          In achieving SDGs goals, targets, and indicators, DPR RI’s
          Inter-Parliamentary Cooperation Agency (BKSAP) - which is the
          cornerstone of parliamentary diplomacy - has formed a Working
          Committee (Panja) on Sustainable Development Goals (SDGs). The
          formation of the Panja SDGs is based on the legal basis of Law Number
          17/2014 concerning the MPR, DPR, DPD, DPRD (MD3) and DPR RI Regulation
          Number 1/2014 and its amendments on the DPR's Rules of Procedure. The
          Committee for Sustainable Development Goals (SDGs) has two objectives:
          (1) to establish early parliamentary involvement in Sustainable
          Development Goals issues as well as; (2) to build partnerships with
          domestic partners as well as international partners for the successful
          implementation of the Sustainable Development Goals (SDGs).
        </p>
      </>
    ),
  },
  {
    id: 3,
    component: <AboutSectionImg3 />,
    header: "THE ROLE OF INDONESIA PARLIAMENT IN SDGs",
    content: (
      <>
        <p className="sdg-tentang-content__text my-3">
          DPR RI is one of the main actors in encouraging and ensuring the
          achievement of SDGs at the national level, particularly through its
          three constitutional functions; budgeting, legislation, and monitoring
          of government performance as the implementer of national policies
          related to SDGs.
        </p>
        <p className="sdg-tentang-content__text my-3">
          The role of parliament is very crucial in efforts to encourage the
          achievement of people-centered SDGs programs and policies through
          representative functions, encouraging community-centered development,
          and being able to reflect and be responsive to the needs of their
          constituents.
        </p>

        <p className="sdg-tentang-content__text my-3">
          At the national level, Parliament is also one of the main stakeholders
          who are expected to actively participate in the implementation and
          achievement of the SDGs in Indonesia, along with other stakeholders
          which include:
          <br />
          1. Government
          <br />
          2. Academics and Experts
          <br />
          3. Philanthropy and Business/Private Actor
          <br />
          4. Community Organizations and Media
          <br />
        </p>
        <p className="sdg-tentang-content__text my-3">
          Indonesia has also applied the inclusive principle of "No One Left
          Behind," where all stakeholders have equality and play an active role
          through their roles and capacities in supporting the achievement of
          SDGs.
        </p>
        <p className="sdg-tentang-content__text my-3">
          DPR RI as the national legislative body, based on the agreement in the
          Hanoi Declaration in 2015 also has a role to:
        </p>
        <p className="sdg-tentang-content__text my-3">
          1. Support the implementation of the goals contained in the SDGs
          through the preparation of legislation that is harmonious with the
          indicators that have been set.
          <br />
          2. Support the dissemination and localization of SDGs at the national
          and local levels.
          <br />
          3. Holding governments accountable for the goals they have subscribed
          to, and to make sure that enabling laws are passed and budgets
          adopted.
        </p>
      </>
    ),
  },
];

const TentangKami = () => {
  const language = useQuery().get("language");

  const configs = language === "INA" ? indonesia : english;

  const renderedContent = configs.map(({ id, content, ...props }) => (
    <ContentBody key={id} {...props}>
      {content}
    </ContentBody>
  ));

  const regulationText =
    language === "INA"
      ? "Regulasi Tujuan Pembangunan Berkelanjutan mengacu pada aturan-aturan yang sudah ditetapkan baik Peraturan Presiden maupun undang-undang yang mengatur pembangunan berkelanjutan sesuai sub-indikator yang telah ditetapkan"
      : "Regulation of the Sustainable Development Goals refers to the rules that have been created, both Presidential Regulations and laws governing sustainable development according to the sub-indicators that have been set.";
  return (
    <>
      <Topbar />
      <HeaderTitle title="SDGs DPR RI" />
      <section className="sdg-tentang container-fluid d-flex flex-column align-items-center">
        <LanguageSwitch />
        <div className="sdg-tentang-content py-lg-4 py-0 mt-lg-4 mt-0">
          {renderedContent}
        </div>
        <div className="sdg-tentang-regulasi my-3">
          {/* <h3>REGULASI</h3> */}
          <h3>{language === "INA" ? "REGULASI" : "REGULATION"}</h3>
          <p className="sdg-tentang-regulasi__text my-4">{regulationText}</p>
          <Link className="sdg-link-glow mt-4" to="/knowledge-hub">
            LIHAT KNOWLEDGE HUB
          </Link>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TentangKami;
