import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_URL}/login`;

const login = async (username, password) => {
  const headerReq = {
    headers: {
      "Content-Type": "application/json",
      password,
    },
  };
  const result = await axios.post(baseUrl, { username }, headerReq);
  return result;
};

export { login };
