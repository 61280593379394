import { Button } from "reactstrap";

import { ReactComponent as Download } from "assets/img/Download.svg";

// import "./UploadBtn.scss";

const UploadBtn = ({ onClick }) => {
  return (
    <Button
      className="sdg-admin-btn--upload"
      onClick={onClick ? onClick : () => {}}
    >
      <Download className="mx-2 mr-3 sdg-admin-btn__icon" />
      Upload File
    </Button>
  );
};

export default UploadBtn;
