import { useEffect, useState } from "react";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import moment from "moment";
import { BsPencil } from "react-icons/bs";
import { BiTrashAlt } from "react-icons/bi";
import { Alert } from "reactstrap";

import AdminDataTable from "components/AdminDataTable/AdminDataTable";
import AdminContentHeader from "components/layout/AdminLayout/AdminContentHeader";
import AddNewAdmin from "./AddNewAdmin";
import EditAdmin from "./EditAdmin";

import { fetchAllUsers, deleteUser } from "utils/api/UserAPI";

import { useCheckLogin } from "utils/hooks";

const AdminManage = () => {
  const { push } = useHistory();
  const { path, url } = useRouteMatch();

  useCheckLogin();

  const [users, setUsers] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const removeUser = async (username, userId) => {
    setIsLoading(true);
    try {
      await deleteUser(username);
      const filteredUsers = users.filter((user) => user.id !== userId);

      setUsers(filteredUsers);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.response.data.message);
    }
  };

  const refetchUsers = async () => {
    setIsLoading(true);
    const { result } = await fetchAllUsers();
    setUsers(result);
    setIsLoading(false);
  };

  const columns = [
    {
      key: "id",
      text: "No.",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
      width: "70px",
      cell: (_, index) => index + 1,
    },
    {
      key: "email",
      text: "Email",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
    },
    {
      key: "is_active",
      text: "Status",
      sortable: true,
      width: "175px",
      TrOnlyClassName: "sdg-datatable__table-head",
      cell: (record) => (record.is_active ? "Aktif" : "Nonaktif"),
    },
    {
      key: "created_at",
      text: "Date",
      sortable: true,
      width: "175px",
      TrOnlyClassName: "sdg-datatable__table-head",
      cell: ({ created_at }) => moment(created_at).format("D MMMM YYYY"),
    },
    {
      key: "action",
      text: "Action",
      width: "145px",
      cell: ({ id, username }) => {
        return (
          <>
            <div className="sdg-datatable__button-group">
              <button
                className="sdg-datatable__button-edit"
                onClick={() => push(`${url}/edit-user/${username}`)}
              >
                <BsPencil color="#30D569" size="20" />
              </button>
              <button
                className="sdg-datatable__button-delete"
                onClick={() =>
                  window.confirm("Are you sure?") && removeUser(username, id)
                }
              >
                <BiTrashAlt color="#FD5457" size="20" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { result } = await fetchAllUsers();
        setUsers(result);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <>
      <Route exact path={path}>
        {error && <Alert color="danger">{error}</Alert>}
        <AdminContentHeader
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          title="DATA ADMIN"
          btnTo={`${url}/add-new-admin`}
          btnText="Tambah Admin"
          add
        />
        <AdminDataTable columns={columns} data={users} loading={isLoading} />
      </Route>
      <Route exact path={`${path}/add-new-admin`}>
        <AddNewAdmin onAdd={refetchUsers} />
      </Route>
      <Route exact path={`${path}/edit-user/:userName`}>
        <EditAdmin onAdd={refetchUsers} />
      </Route>
    </>
  );
};

export default AdminManage;
