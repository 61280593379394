import ReactDatatable from "@ashvin27/react-datatable";
import "./AdminDataTable.scss";

const AdminDataTable = ({
  columns,
  data,
  download = null,
  loading,
  error = "Sedang memuat data...",
}) => {
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    filename: "regulasi",
    language: {
      loading_text: error,
    },
  };

  const configdownload = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    filename: "regulasi",
    button: {
      excel: true,
    },
    language: {
      loading_text: error,
    },
  };

  return (
    <div className="sdg-datatable-content">
      <ReactDatatable
        className="sdg-datatable table custom-style-table"
        tHeadClassName="custom-header-style"
        config={download ? configdownload : config}
        records={data}
        columns={columns}
        id="sdg-datatable"
        loading={loading}
      />
    </div>
  );
};

export default AdminDataTable;
