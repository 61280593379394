import DatePicker from "react-datepicker";
import DropdownIcon from "assets/img/dropdown-icon.svg";
import { ButtonRectangle, ButtonDate } from "components/ButtonAdmin";
import "./AdminContentHeader.scss";

const AdminContentHeader = ({
  startDate,
  endDate,
  setDateRange,
  title,
  btnTo,
  btnText,
  clickFilter,
  add = null,
}) => {
  return (
    <>
      <div className="sdg-admin-content__header">
        <p className="sdg-admin-content__header-title">{title}</p>
        <div className="sdg-datepicker">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            maxDate={new Date()}
            className="sdg-datepicker__input-date mr-2"
            dateFormat="MMM d , yyyy"
            placeholderText="Pilih Tanggal"
            isClearable
          />
          <img
            src={DropdownIcon}
            alt="dropdown-icon"
            className="sdg-datepicker__input-date-dropdown"
          />
        </div>
        <ButtonDate onClick={clickFilter}>Filter</ButtonDate>
        {add && <ButtonRectangle to={btnTo}>{btnText}</ButtonRectangle>}
      </div>
    </>
  );
};

export default AdminContentHeader;
