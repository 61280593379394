import React from "react";
import ContentLoader from "react-content-loader";
const LokalisasiDetailLoader = (props) => {
  return (
    <div>
      <ContentLoader
        speed={2}
        width={"100%"}
        height={550}
        viewBox="0 0 100% 721.36"
        backgroundColor="#132442"
        foregroundColor="#24B9F2"
        {...props}
      >
        <rect x="8" y="96" rx="0" ry="0" width="580" height="15" />
        <rect x="126" y="1" rx="0" ry="0" width="339" height="13" />
        <rect x="95" y="23" rx="0" ry="0" width="416" height="13" />
        <rect x="131" y="47" rx="0" ry="0" width="321" height="13" />
        <rect x="7" y="120" rx="0" ry="0" width="580" height="15" />
        <rect x="8" y="144" rx="0" ry="0" width="580" height="15" />
        <rect x="-1" y="291" rx="0" ry="0" width="591" height="115" />
        <rect x="2" y="426" rx="0" ry="0" width="591" height="115" />
        <rect x="8" y="188" rx="0" ry="0" width="580" height="15" />
        <rect x="7" y="212" rx="0" ry="0" width="580" height="15" />
        <rect x="8" y="236" rx="0" ry="0" width="580" height="15" />
      </ContentLoader>
    </div>
  );
};

export default LokalisasiDetailLoader;
