const getUserId = () => localStorage.getItem("userId");

const setUserId = (userId) => {
  localStorage.setItem("userId", userId);
};

const getUserName = () => localStorage.getItem("userName");

const setUserName = (userName) => {
  localStorage.setItem("userName", userName);
};

export { getUserId, setUserId, getUserName, setUserName };
