import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment";

import { fetchArticleById } from "utils/api/ArtikelAPI";

import Manage from "components/Manage";
import AdminTitle from "components/AdminTitle";

import "./ViewArticle.scss";

const ViewArticle = () => {
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { articleId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { payload } = await fetchArticleById(articleId);
        setArticle(payload.artikel);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [articleId]);

  return (
    <>
      <AdminTitle viewTitle capitalize>
        {article && !isLoading && article.judul}
      </AdminTitle>
      <Manage>
        <Manage.Body className="sdg-admin-manage-article">
          {!article ? (
            <div className="text-center">
              <Spinner color="danger" className="ml-auto" />
            </div>
          ) : (
            <>
              <div className="sdg-admin-manage-article__header">
                <h1 className="sdg-admin-manage-article__top-heading">
                  {article.judul}
                </h1>
                <span className="sdg-admin-manage-article__meta-text">
                  By {article.creator_username} -
                  {moment(article.created_at).format("D-MM-YYYY")}
                </span>
              </div>
              <div className="sdg-admin-manage-article__hero">
                <img
                  src={`${article.file_dir}`}
                  alt="Hero"
                  className="sdg-admin-manage-article__image"
                />
              </div>
              <div
                className="sdg-admin-manage-article__body"
                dangerouslySetInnerHTML={{ __html: article.body }}
              />
            </>
          )}
        </Manage.Body>
      </Manage>
    </>
  );
};

export default ViewArticle;
