import { useEffect, useState } from "react";
import { Link, useRouteMatch, Route } from "react-router-dom";

import * as DokumenAPI from "utils/api/DokumenAPI";
import * as RegulasiAPI from "utils/api/RegulasiAPI";
import * as LokalisasiAPI from "utils/api/LokalisasiAPI";
import { fetchAllCommunityOfPractices } from "utils/api/CoPAPI";
import { Topbar, Footer } from "components/layout";
import HeaderTitle from "components/HeaderTitle";
import KnowledgeHubCardGroup from "./KnowledgeHubCardGroup";
import KnowledgeHubSection from "./KnowledgeHubSection";
import MoreKnowledgeHub from "./MoreKnowledgeHub";
import SkeletonKnowledgeHub from "components/skeleton/KnowledgeHub";

import "./KnowledgeHub.scss";

const KnowledgeHub = () => {
  const match = useRouteMatch();

  const [dokumenList, setDokumenList] = useState(null);
  const [regulasiList, setRegulasiList] = useState(null);
  const [lokalisasiList, setLokalisasiList] = useState(null);
  const [communityOfPracticesList, setCommunityOfPracticesList] =
    useState(null);

  const getDokumen = async () => {
    try {
      const result = await DokumenAPI.getByLimit(4);
      setDokumenList(result.payload.dokumen_list);
    } catch (err) {
      console.log(err);
    }
  };

  const getRegulasi = async () => {
    try {
      const result = await RegulasiAPI.getByLimit(4);
      setRegulasiList(result.payload.regulasi_list);
    } catch (err) {
      console.log(err);
    }
  };

  const getLokalisasi = async () => {
    try {
      const result = await LokalisasiAPI.getByLimit(4);
      setLokalisasiList(result.payload.lokalisasi_list);
    } catch (err) {
      console.log(err);
    }
  };

  const getCommunityOfPractice = async () => {
    try {
      const {
        payload: { community_list },
      } = await fetchAllCommunityOfPractices(4);
      setCommunityOfPracticesList(community_list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getDokumen();
      getRegulasi();
      getLokalisasi();
      getCommunityOfPractice();
    }, 1500);
  }, []);

  return (
    <>
      <Topbar />
      <Route exact path={match.path}>
        <HeaderTitle title="KNOWLEDGE HUB" subtitle="sdgs knowledge hub" />
        <KnowledgeHubSection title="DOKUMEN">
          {dokumenList && <KnowledgeHubCardGroup datas={dokumenList} />}
          {!dokumenList && <SkeletonKnowledgeHub />}
          <div className="col-12 text-center">
            <Link
              className="sdg-link-glow"
              to={`${match.url}/view-more?category=dokumen`}
            >
              LIHAT SELENGKAPNYA
            </Link>
          </div>
        </KnowledgeHubSection>
        <KnowledgeHubSection title="REGULASI">
          {regulasiList && <KnowledgeHubCardGroup datas={regulasiList} />}
          {!regulasiList && <SkeletonKnowledgeHub />}
          <div className="col-12 text-center">
            <Link
              className="sdg-link-glow"
              to={`${match.url}/view-more?category=regulasi`}
            >
              LIHAT SELENGKAPNYA
            </Link>
          </div>
        </KnowledgeHubSection>
        <KnowledgeHubSection title="MELOKALKAN SDGs">
          {lokalisasiList && (
            <KnowledgeHubCardGroup
              datas={lokalisasiList}
              detail={true}
              path="/lokalisasi/"
            />
          )}
          {!lokalisasiList && <SkeletonKnowledgeHub />}
          <div className="col-12 text-center">
            <Link
              className="sdg-link-glow"
              to={`${match.url}/view-more?category=lokalisasi`}
            >
              LIHAT SELENGKAPNYA
            </Link>
          </div>
        </KnowledgeHubSection>
        <KnowledgeHubSection title="FORUM COMMUNITY OF PRACTICE">
          {communityOfPracticesList && (
            <KnowledgeHubCardGroup
              datas={communityOfPracticesList}
              detail={true}
              path="/community-of-practice/"
              isCoPView
            />
          )}
          {!communityOfPracticesList && <SkeletonKnowledgeHub />}
          <div className="text-center">
            <Link
              className="sdg-link-glow"
              to={`${match.url}/view-more?category=community-of-practice`}
            >
              LIHAT SELENGKAPNYA
            </Link>
          </div>
        </KnowledgeHubSection>
      </Route>
      <Route exact path={`${match.path}/view-more`}>
        <MoreKnowledgeHub />
      </Route>
      <Footer />
    </>
  );
};

export default KnowledgeHub;
