import { useEffect, useState } from "react";
import { Button } from "reactstrap";

import * as DokumenAPI from "utils/api/DokumenAPI";
import * as RegulasiAPI from "utils/api/RegulasiAPI";
import * as LokalisasiAPI from "utils/api/LokalisasiAPI";
import { fetchAllCommunityOfPractices } from "utils/api/CoPAPI";
import { useQuery } from "utils/hooks";

import SkeletonKnowledgeHub from "components/skeleton/KnowledgeHub";
import HeaderTitle from "components/HeaderTitle";
import KnowledgeHubCardGroup from "./KnowledgeHubCardGroup";
import SearchBar from "components/SearchBar";

const MoreKnowledgeHub = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [list, setList] = useState(null);
  const [limitData, setLimitData] = useState(8);
  const [initList, setInitList] = useState(null);
  const category = useQuery().get("category");

  useEffect(() => {
    getData();
  }, [limitData]);

  const getData = async () => {
    setIsLoading(true);
    try {
      let result;
      if (category === "dokumen") {
        result = await DokumenAPI.getByLimit(limitData);
        setTimeout(() => {
          setList(result.payload.dokumen_list);
          setInitList(result.payload.dokumen_list);
          setIsLoading(false);
        }, 1000);
      } else if (category === "regulasi") {
        result = await RegulasiAPI.getByLimit(limitData);
        setTimeout(() => {
          setList(result.payload.regulasi_list);
          setInitList(result.payload.regulasi_list);
          setIsLoading(false);
        }, 1000);
      } else if (category === "lokalisasi") {
        result = await LokalisasiAPI.getByLimit(limitData);
        setTimeout(() => {
          setList(result.payload.lokalisasi_list);
          setInitList(result.payload.lokalisasi_list);
          setIsLoading(false);
        }, 1000);
      } else if (category === "community-of-practice") {
        result = await fetchAllCommunityOfPractices(limitData);
        setTimeout(() => {
          setList(result.payload.community_list);
          setInitList(result.payload.community_list);
          setIsLoading(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const search = (e) => {
    setFilter(e.target.value);
    if (!e.target.value) {
      setList(initList);
    } else {
      const result = initList.filter((data) =>
        data.judul.toLowerCase().includes(e.target.value.toLocaleLowerCase())
      );
      setList(result);
    }
  };

  const title =
    category === "lokalisasi"
      ? "MELOKALKAN SDGs"
      : category === "community-of-practice"
      ? "FORUM COMMUNITY OF PRACTICE"
      : category.toUpperCase();

  return (
    <>
      <HeaderTitle title={title} subtitle={`SDGS KNOWLEDGE HUB • ${title}`} />
      <div className="w-100 d-flex justify-content-center my-4 pb-5">
        <SearchBar
          placeholder={`Masukkan Nama ${category}`}
          value={filter}
          onChange={(e) => search(e)}
        />
      </div>
      <section className="container-fluid">
        {isLoading && <SkeletonKnowledgeHub />}
        {!isLoading && (
          <KnowledgeHubCardGroup
            datas={list}
            detail={
              category.includes("lokalisasi") || category.includes("community")
            }
            path={`/${category}/`}
          />
        )}
        <div className="w-100 d-flex justify-content-center">
          <Button
            className="sdg-btn-glow"
            onClick={() => setLimitData(limitData + 8)}
          >
            LIHAT LEBIH BANYAK
          </Button>
        </div>
      </section>
    </>
  );
};

export default MoreKnowledgeHub;
