import { useState, useEffect } from "react";
import { MapContainer, Tooltip } from "react-leaflet";
import Choropleth from "react-leaflet-choropleth";
import Select from "react-select";
import { Label, Spinner, Button } from "reactstrap";
import chroma from "chroma-js";

import { mapConfs } from "utils/helpers";
import Legend from "./Legend";
import {
  getInitialMap,
  getAvailableGoals,
  getAvailableTarget,
  getTargetIndicator,
  getMaps,
} from "utils/api/DashboardAPI";

const Map = () => {
  const [geoJsonMap, setGeoJsonMap] = useState(null);
  const [mapTitle, setMapTitle] = useState(null);
  const [mapSubtitle, setMapSubtitle] = useState(null);
  const [legend, setLegend] = useState(null);
  const [isLoadMap, setIsLoadMap] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [year, setYear] = useState(null);
  const [level, setLevel] = useState(null);
  const [isLevelDisabled, setIsLevelDisabled] = useState(true);

  const [goals, setGoals] = useState(null);
  const [optsGoals, setOptsGoals] = useState(null);
  const [isGoalsDisabled, setIsGoalsDisabled] = useState(true);

  const [target, setTarget] = useState(null);
  const [optsTarget, setOptsTarget] = useState(null);
  const [isTargetDisabled, setIsTargetDisabled] = useState(true);

  const [subTarget, setSubTarget] = useState(null);
  const [optsSubTarget, setOptsSubTarget] = useState(null);
  const [isSubTargetDisabled, setIsSubTargetDisabled] = useState(true);

  const initialMap = async () => {
    setIsLoadMap(true);

    try {
      const res = await getInitialMap();
      const { tahun, scope, target, sub_target } = res;
      const params = {
        tahun,
        scope,
        target_id: target.indikator_id,
        sub_target: sub_target.val,
      };
      setMapTitle(target.judul);
      setMapSubtitle(sub_target.label);

      const result = await getMaps(params);
      setGeoJsonMap(result);
      getLegend(result);
      setTimeout(() => {
        setIsLoadMap(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const changeYear = (e) => {
    setYear(e.value);
    setLevel(null);

    setIsLevelDisabled(false);
    setIsGoalsDisabled(true);
    setIsTargetDisabled(true);
    setIsSubTargetDisabled(true);
    setIsBtnDisabled(true);
  };

  const changeLevel = async (e) => {
    setLevel(e.value);
    setGoals(null);
    setOptsGoals(null);

    setIsGoalsDisabled(true);
    setIsTargetDisabled(true);
    setIsSubTargetDisabled(true);
    setIsBtnDisabled(true);

    const params = { tahun: year, scope: e.value };
    try {
      const result = await getAvailableGoals(params);
      const goals = result.map((item) => ({
        value: item.goal_id,
        label: item.goal_name,
      }));

      setOptsGoals(goals);
      setIsGoalsDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const changeGoals = async (e) => {
    setGoals(e.value);
    setTarget(null);
    setOptsTarget(null);

    setIsTargetDisabled(true);
    setIsSubTargetDisabled(true);
    setIsBtnDisabled(true);

    const params = { tahun: year, scope: level, goal: e.value };
    try {
      const result = await getAvailableTarget(params);
      const target = result.map((item) => ({
        value: item.indikator_id,
        label: item.judul,
      }));

      setOptsTarget(target);
      setIsTargetDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const changeTarget = async (e) => {
    setTarget(e.value);
    setMapTitle(e.label);
    setSubTarget(null);
    setOptsSubTarget(null);

    setIsSubTargetDisabled(true);
    setIsBtnDisabled(true);

    const params = { tahun: year, target_id: e.value };
    try {
      const result = await getTargetIndicator(params);
      const subTarget = result.map((item) => ({
        value: item.val,
        label: item.label,
      }));

      setOptsSubTarget(subTarget);
      setIsSubTargetDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const changeSubTarget = (e) => {
    setSubTarget(e.value);
    setMapSubtitle(e.label);
    setIsBtnDisabled(false);
  };

  const showData = async () => {
    setLegend(null);
    setIsLoadMap(true);
    const params = {
      tahun: year,
      scope: level,
      target_id: target,
      sub_target: subTarget,
    };

    try {
      const result = await getMaps(params);
      setGeoJsonMap(result);
      getLegend(result);
      setIsLoadMap(false);
    } catch (err) {
      setIsLoadMap(false);
      console.log(err);
    }
  };

  const ShowTooltip = ({ feature }) => (
    <Tooltip sticky>
      <b>{feature.properties.name}: </b>
      {feature.properties.item_value} %
    </Tooltip>
  );

  const getLegend = (data) => {
    const { features } = data;
    const values = features.map((item) => item.properties.item_value);
    const limit = chroma.limits(values, "q", 5 - 1);

    const legend = {
      limits: [0, ...limit],
      colors: chroma.scale(["#ffffb2", "#bd0026"]).colors(5),
    };
    setLegend(legend);
  };

  useEffect(() => {
    initialMap();
  }, []);

  // const onEachFeature = (feature, layer) => {
  //   layer.on("mouseover", () => {
  //     const { name, item_value } = feature.properties;
  //     layer.bindPopup(`${name}: ${item_value ? item_value : 0}%`).openPopup();
  //   });
  // };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h4 className="text-center text-uppercase">
            Peta Lokasi Program SDGs Berdasarkan Indikator
          </h4>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 d-flex flex-column flex-md-row justify-content-center align-items-md-end">
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Tahun</Label>
            <Select
              name="year"
              options={mapConfs.optsYear}
              placeholder="Tahun"
              styles={mapConfs.selectStyles}
              onChange={(e) => changeYear(e)}
            />
          </div>
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Tingkat</Label>
            <Select
              name="level"
              options={mapConfs.optsLevelMap}
              placeholder="Tingkat"
              styles={mapConfs.selectStyles}
              isDisabled={isLevelDisabled}
              onChange={(e) => changeLevel(e)}
            />
          </div>
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Indikator</Label>
            <Select
              name="goals"
              options={optsGoals}
              placeholder="Indikator"
              styles={mapConfs.selectStyles}
              isDisabled={isGoalsDisabled}
              onChange={(e) => changeGoals(e)}
            />
          </div>
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Sub Indikator</Label>
            <Select
              name="target"
              options={optsTarget}
              placeholder="Sub Indikator"
              styles={mapConfs.selectStyles}
              isDisabled={isTargetDisabled}
              onChange={(e) => changeTarget(e)}
            />
          </div>
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Kategori</Label>
            <Select
              name="target"
              options={optsSubTarget}
              placeholder="Kategori"
              styles={mapConfs.selectStyles}
              isDisabled={isSubTargetDisabled}
              onChange={(e) => changeSubTarget(e)}
            />
          </div>
          <div className="d-flex flex-column mb-3 mb-md-0">
            <Button
              disabled={isBtnDisabled}
              className="sdg-btn-glow"
              onClick={showData}
            >
              Tampilkan Data
            </Button>
          </div>
        </div>
      </div>
      <div className="row mt-3 mt-md-5">
        <div className="col-12">
          <h6 className="text-center">{mapTitle && mapTitle}</h6>
          <h6 className="text-center">{mapSubtitle && `(${mapSubtitle})`}</h6>
          <MapContainer
            center={[-0.872298, 116.50184]}
            zoom={5}
            scrollWheelZoom={false}
            maxZoom={18}
            style={{ height: "500px" }}
            // whenCreated={setMap}
          >
            {isLoadMap && (
              <div className="d-flex justify-content-center">
                <Spinner
                  color="info"
                  style={{
                    width: "10rem",
                    height: "10rem",
                    marginTop: "10%",
                  }}
                />
              </div>
            )}
            {!isLoadMap && geoJsonMap && (
              <Choropleth
                data={geoJsonMap && geoJsonMap}
                valueProperty={(feature) => feature.properties.item_value}
                scale={["#ffffb2", "#bd0026"]}
                steps={5}
                mode="q"
                style={mapConfs.mapStyle}
              >
                <ShowTooltip />
              </Choropleth>
            )}
            {legend && <Legend limits={legend.limits} colors={legend.colors} />}
          </MapContainer>
          <p>
            <small>Sumber Data: BPS</small>
          </p>
        </div>
      </div>
    </>
  );
};

export default Map;
