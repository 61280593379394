import "./ButtonAdmin.scss";
import { Link } from "react-router-dom";

const ButtonRectangle = ({ children, to }) => {
  return (
    <Link to={to} className="sdg-button-rectangle">
      {children}
    </Link>
  );
};

export default ButtonRectangle;
