import SkeletonFile from "./SkeletonFile";

const SkeletonKnowledgeHub = () => {
  return (
    <div className="sdg-knowledgehub__content row">
      <div className="col-12 col-md-3 sdg-knowledgehub-card">
        <SkeletonFile />
      </div>
      <div className="col-12 col-md-3 sdg-knowledgehub-card">
        <SkeletonFile />
      </div>
      <div className="col-12 col-md-3 sdg-knowledgehub-card">
        <SkeletonFile />
      </div>
      <div className="col-12 col-md-3 sdg-knowledgehub-card">
        <SkeletonFile />
      </div>
    </div>
  );
};

export default SkeletonKnowledgeHub;
