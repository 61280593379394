import { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Link, Switch, Route } from "react-router-dom";

import AdminDataTable from "components/AdminDataTable";
import AdminContentHeader from "components/layout/AdminLayout/AdminContentHeader";

import * as DapilAPI from "utils/api/DapilAPI";
import { BsPencil } from "react-icons/bs";
import { BiTrashAlt } from "react-icons/bi";
import { useCheckLogin } from "utils/hooks";

import moment from "moment";
import AddDapil from "./AddDapil";
import EditDapil from "./EditDapil";

const Dapil = () => {
  let history = useHistory();
  const { path, url } = useRouteMatch();
  useCheckLogin();

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      key: "id",
      text: "No.",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
      width: "70px",
      cell: (record, index) => {
        return index + 1;
      },
    },
    {
      key: "provinsi",
      text: "Provinsi",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
    },
    {
      key: "created_at",
      text: "Date",
      sortable: true,
      width: "175px",
      TrOnlyClassName: "sdg-datatable__table-head",
      cell: (record) => {
        return moment(record.created_at).format("DD MMMM YYYY");
      },
    },
    {
      key: "action",
      text: "Action",
      width: "125px",
      cell: (record, index) => {
        return (
          <>
            <div className="sdg-datatable__button-group">
              <Link
                to={`${url}/edit?id=${record.id}&provinsi=${record.provinsi}`}
              >
                <button className="sdg-datatable__button-edit">
                  <BsPencil color="#30D569" size="20" />
                </button>
              </Link>
              <button
                className="sdg-datatable__button-delete"
                onClick={() =>
                  window.confirm("Are you sure?") && deleteList(record.id)
                }
              >
                <BiTrashAlt color="#FD5457" size="20" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const getData = async () => {
    setIsLoading(true);
    try {
      const result = await DapilAPI.read();
      if (result.status === 200) {
        setTimeout(() => {
          setData(result.payload.dapil_list);
          setIsLoading(false);
        }, 300);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(true);
      setError(err.response.data.message);
    }
  };

  const handleFormAdd = async (e, provinsi) => {
    e.preventDefault();
    setIsLoading(true);

    const dataBaru = { provinsi: provinsi };
    try {
      const result = await DapilAPI.create(dataBaru);
      if (result.status === 201) {
        setIsLoading(false);
        setIsError(false);
        history.push(path);
        getData();
      }
    } catch (err) {
      setIsError(true);
      setAlert({ status: "danger", msg: err.response.data.message });
      setIsLoading(false);
    }
  };

  const handleFormRename = async (e, id, provinsi) => {
    e.preventDefault();
    setIsLoading(true);

    const dataBaru = { id_dapil: id, provinsi: provinsi };
    try {
      const result = await DapilAPI.rename(dataBaru);
      if (result.status === 200) {
        setIsLoading(false);
        setIsError(false);
        history.push(path);
        getData();
      }
    } catch (err) {
      setIsError(true);
      setAlert({ status: "danger", msg: err.response.data.message });
      setIsLoading(false);
    }
  };

  const deleteList = async (id) => {
    setIsLoading(true);

    try {
      const result = await DapilAPI.remove(id);
      if (result.status === 200) {
        getData();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <Switch>
      <Route exact path={path}>
        <section className="sdg-daerah-pemilihan sdg-admin-manage">
          <AdminContentHeader
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
            title="DAERAH PEMILIHAN"
            btnTo={`${url}/add`}
            btnText="Tambah Daerah Pemilihan"
            add
            error={error && error}
          />
          {error ? (
            <AdminDataTable
              columns={columns}
              data={data}
              loading={isLoading}
              error={error}
            />
          ) : (
            <AdminDataTable columns={columns} data={data} loading={isLoading} />
          )}
        </section>
      </Route>
      <Route path={`${path}/add`}>
        <AddDapil
          handleFormAdd={handleFormAdd}
          isLoading={isLoading}
          isError={isError}
          setIsError={setIsError}
          alert={alert}
        />
      </Route>
      <Route path={`${path}/edit`}>
        <EditDapil
          handleFormRename={handleFormRename}
          isLoading={isLoading}
          isError={isError}
          setIsError={setIsError}
          alert={alert}
        />
      </Route>
    </Switch>
  );
};

export default Dapil;
