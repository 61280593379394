import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_URL}/dashboard`;

const getInitialMap = async () => {
  const url = `${baseUrl}/get_initial_map_dropdown`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getInitialBar = async () => {
  const url = `${baseUrl}/get_initial_dapil_dropdown`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getInitialLine = async () => {
  const url = `${baseUrl}/get_initial_line_dropdown`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getAvailableGoals = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_available_goals?${params}`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getAvailableTarget = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_available_target?${params}`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getTargetIndicator = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_available_target_indicator?${params}`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getMaps = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_maps?${params}`;
  const res = await axios.get(url);

  return res.data.payload.data;
};

const getYearlyGoals = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_available_yearly_goals?${params}`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getYearlyTarget = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_available_yearly_target?${params}`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getYearlySubTarget = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_available_yearly_sub_target?${params}`;
  const res = await axios.get(url);

  return res.data.payload;
};

const getLineChart = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_per_dapil_linechart?${params}`;
  const res = await axios.get(url);

  return res.data.payload.data;
};

const getBarChart = async (req) => {
  const params = new URLSearchParams(req).toString();
  const url = `${baseUrl}/get_dapil_barchart?${params}`;
  const res = await axios.get(url);

  return res.data.payload.data;
};

export {
  getInitialMap,
  getInitialBar,
  getInitialLine,
  getAvailableGoals,
  getAvailableTarget,
  getTargetIndicator,
  getMaps,
  getYearlyGoals,
  getYearlyTarget,
  getYearlySubTarget,
  getLineChart,
  getBarChart,
};
