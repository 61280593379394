import { Link } from "react-router-dom";

const FrontDetailBreadCrumb = ({ text }) => {
  return (
    <p className="text-center m-0">
      <i>
        <Link to="/" className="sdg-front-detail__link">
          Beranda
        </Link>{" "}
        / {text}
      </i>
    </p>
  );
};

export default FrontDetailBreadCrumb;
