import "./MasukanCardGroup.scss";

import MasukanCardItem from "./MasukanCardItem";

const MasukanCardGroup = ({ items, onItemClick }) => {
  const renderedItem = items.map(({ id, name, image }) => (
    <MasukanCardItem
      key={id}
      item={{ id, name, image }}
      onItemClick={onItemClick}
    />
  ));
  return <div className="sdg-card-group row">{renderedItem}</div>;
};

export default MasukanCardGroup;
