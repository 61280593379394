import "./MasukanCardItem.scss";

const MasukanCardItem = ({ item: { id, name, image }, onItemClick }) => {
  return (
    <div
      className="col-lg-3 col-xl-2 sdg-masukan-card-container"
      onClick={id !== 18 ? () => onItemClick(id, name, image) : () => {}}
    >
      <div
        className={`sdg-masukan-card-item 
        ${id === 18 && "sdg-masukan-card-item--empty"}`}
      >
        <img src={image} alt={name} className="sdg-masukan-card-item__image" />
      </div>
    </div>
  );
};

export default MasukanCardItem;
