import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Collapse } from "reactstrap";

import * as RegulasiAPI from "utils/api/RegulasiAPI";

import logoDpr from "assets/img/logo-dpr-ri.png";
import list from "./data-footer.json";

import "./Footer.scss";

const Footer = () => {
  const [data, setData] = useState(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenHub, setIsOpenHub] = useState(false);
  const [isOpenLink, setIsOpenLink] = useState(false);
  const [isOpenKontak, setIsOpenKontak] = useState(false);
  const menuToggle = () => setIsOpenMenu(!isOpenMenu);
  const hubToggle = () => setIsOpenHub(!isOpenHub);
  const linkToggle = () => setIsOpenLink(!isOpenLink);
  const kontakToggle = () => setIsOpenKontak(!isOpenKontak);

  useEffect(() => {
    getRegulasi();
  }, []);

  const getRegulasi = async () => {
    try {
      const result = await RegulasiAPI.getByLimit(4);
      setData(result.payload.regulasi_list);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <section className="container-fluid sdg-footer d-none d-md-block">
        <div className="row">
          <div className="col-3">
            <div className="sdg-footer__logo">
              <img src={logoDpr} alt="Logo DPR RI" />
              <p>
                DEWAN PERWAKILAN RAKYAT
                <br />
                REPUBLIK INDONESIA
              </p>
              <p>Jl. Jenderal Gatot Subroto - Senayan - Jakarta 10270</p>
            </div>
          </div>
          <div className="col-2">
            <div className="sdg-footer__title">menu</div>
            <div className="sdg-footer__menu">
              {list.menu.map((item, index) => (
                <div key={index} className="sdg-footer__menu-list">
                  <Link to={item.to}>{item.label}</Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-3">
            <div className="sdg-footer__title">knowledge hub</div>
            <div className="sdg-footer__menu">
              {data &&
                data.map((item, index) => (
                  <div key={index} className="sdg-footer__menu-list">
                    <a href={item.file_dir} target="_blank" rel="noreferrer">
                      {item.judul}
                    </a>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-2">
            <div className="sdg-footer__title">link</div>
            <div className="sdg-footer__menu">
              {list.link.map((item, index) => (
                <div key={index} className="sdg-footer__menu-list">
                  <Link to={item.to}>{item.label}</Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-2">
            <div className="sdg-footer__title">kontak kami</div>
            <div className="sdg-footer__menu">
              {list.kontak.map((item, index) => (
                <div key={index} className="sdg-footer__menu-list">
                  <Link to={{ pathname: item.to }} target="_blank">
                    {item.label}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row sdg-footer__copyright">
          <p>Copyright © 2021 SDGs DPR</p>
        </div>
      </section>
      <section className="container-fluid sdg-footer d-md-none">
        <div className="row mb-4 justify-content-center">
          <div className="sdg-footer__logo">
            <img src={logoDpr} alt="Logo DPR RI" />
            <p>
              DEWAN PERWAKILAN RAKYAT
              <br />
              REPUBLIK INDONESIA
            </p>
            <p>Jl. Jenderal Gatot Subroto - Senayan - Jakarta 10270</p>
          </div>
        </div>
        <div className="row flex-column m-0">
          <div
            className={`sdg-footer__menu ${
              isOpenMenu && "sdg-footer__menu--active"
            }`}
          >
            <div className="sdg-footer__menu-title" onClick={menuToggle}>
              <span>menu</span>
              <MdKeyboardArrowDown size="24" />
            </div>
            <Collapse isOpen={isOpenMenu}>
              {list.menu.map((item, index) => (
                <div key={index} className="sdg-footer__menu-list">
                  <Link to={item.to}>{item.label}</Link>
                </div>
              ))}
            </Collapse>
          </div>
          <div
            className={`sdg-footer__menu ${
              isOpenHub && "sdg-footer__menu--active"
            }`}
          >
            <div className="sdg-footer__menu-title" onClick={hubToggle}>
              <span>knowledge hub</span>
              <MdKeyboardArrowDown size="24" />
            </div>
            <Collapse isOpen={isOpenHub}>
              {data &&
                data.map((item, index) => (
                  <div key={index} className="sdg-footer__menu-list">
                    <a href={item.file_dir} target="_blank" rel="noreferrer">
                      {item.judul}
                    </a>
                  </div>
                ))}
            </Collapse>
          </div>
          <div
            className={`sdg-footer__menu ${
              isOpenLink && "sdg-footer__menu--active"
            }`}
          >
            <div className="sdg-footer__menu-title" onClick={linkToggle}>
              <span>link</span>
              <MdKeyboardArrowDown size="24" />
            </div>
            <Collapse isOpen={isOpenLink}>
              {list.link.map((item, index) => (
                <div key={index} className="sdg-footer__menu-list">
                  <Link to={item.to}>{item.label}</Link>
                </div>
              ))}
            </Collapse>
          </div>
          <div
            className={`sdg-footer__menu ${
              isOpenKontak && "sdg-footer__menu--active"
            }`}
          >
            <div className="sdg-footer__menu-title" onClick={kontakToggle}>
              <span>kontak kami</span>
              <MdKeyboardArrowDown size="24" />
            </div>
            <Collapse isOpen={isOpenKontak}>
              {list.kontak.map((item, index) => (
                <div key={index} className="sdg-footer__menu-list">
                  <Link to={{ pathname: item.to }} target="_blank">
                    {item.label}
                  </Link>
                </div>
              ))}
            </Collapse>
          </div>
        </div>
        <div className="row sdg-footer__copyright">
          <p>Copyright © 2021 SDGs DPR</p>
        </div>
      </section>
    </>
  );
};

export default Footer;
