import * as MockImg from "assets/img/GoalImg";

const GoalsImg = Object.values(MockImg);

const imageName = [
  "Tanpa Kemiskinan",
  "Tanpa Kelaparan",
  "Kehidupan Sehat dan Sejahtera",
  "Pendidikan Berkualitas",
  "Kesetaraan Jender",
  "Air Bersih dan Sanitasi Layak",
  "Energi Bersih dan Terjangkau",
  "Pekerjaan Layak dan Pertumbuhanekonomi",
  "Industri Inovasi dan Infrastruktur",
  "Berkurangnya Kesenjangan",
  "Kota dan Pemukiman yang Berkelanjutan",
  "Konsumsi dan Produksi yang Bertanggung Jawab",
  "Penanganan Perubahan Iklim",
  "Ekosistem Lautan",
  "Ekosistem Daratan",
  "Perdamaian Keadilan dan Kelembagaan yang Tangguh",
  "Kemitraan untuk Mencapai Tujuan",
];

const makeMasukanMockData = (length) => {
  const MOCK_DATA = [];

  for (let i = 1; i <= length; i++) {
    MOCK_DATA.push({
      id: i,
      image: GoalsImg[i - 1],
      name: imageName[i - 1],
    });
  }

  return MOCK_DATA;
};

export default makeMasukanMockData;
