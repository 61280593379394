import { useState, useEffect } from "react";
import Select from "react-select";
import { Label, Spinner, Button } from "reactstrap";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

import { mapConfs } from "utils/helpers";
import {
  getInitialBar,
  getAvailableGoals,
  getAvailableTarget,
  getTargetIndicator,
  getBarChart,
} from "utils/api/DashboardAPI";

echarts.registerTheme("dark", {
  valueAxis: {
    axisLabel: { color: "#fff" },
  },
  categoryAxis: {
    axisLabel: { color: "#fff" },
  },
  legend: {
    textStyle: { color: "#fff" },
  },
});

const Bar = () => {
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [isLoadChart, setIsLoadChart] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [dataChart, setDataChart] = useState(null);
  const [year, setYear] = useState(null);

  const [goals, setGoals] = useState(null);
  const [optsGoals, setOptsGoals] = useState(null);
  const [isGoalsDisabled, setIsGoalsDisabled] = useState(true);

  const [target, setTarget] = useState(null);
  const [optsTarget, setOptsTarget] = useState(null);
  const [isTargetDisabled, setIsTargetDisabled] = useState(true);

  const [subTarget, setSubTarget] = useState(null);
  const [optsSubTarget, setOptsSubTarget] = useState(null);
  const [isSubTargetDisabled, setIsSubTargetDisabled] = useState(true);

  const initialBar = async () => {
    setIsLoadChart(true);

    try {
      const res = await getInitialBar();

      const { tahun, target, sub_target } = res;
      const params = {
        tahun,
        target_id: target.indikator_id,
        sub_target: sub_target.val,
      };
      setTitle(target.judul);
      setSubtitle(sub_target.label);

      const result = await getBarChart(params);
      setDataChart(result);
      setTimeout(() => {
        setIsLoadChart(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const changeYear = async (e) => {
    setYear(e.value);
    setGoals(null);
    setOptsGoals(null);

    setIsGoalsDisabled(true);
    setIsTargetDisabled(true);
    setIsSubTargetDisabled(true);
    setIsBtnDisabled(true);

    const params = { tahun: e.value, scope: "Dapil" };
    try {
      setGoals(null);
      const result = await getAvailableGoals(params);
      const goals = result.map((item) => ({
        value: item.goal_id,
        label: item.goal_name,
      }));

      setOptsGoals(goals);
      setIsGoalsDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const changeGoals = async (e) => {
    setGoals(e.value);
    setTarget(null);
    setOptsTarget(null);

    setIsTargetDisabled(true);
    setIsSubTargetDisabled(true);
    setIsBtnDisabled(true);

    const params = { tahun: year, scope: "Dapil", goal: e.value };
    try {
      const result = await getAvailableTarget(params);
      const target = result.map((item) => ({
        value: item.indikator_id,
        label: item.judul,
      }));

      setOptsTarget(target);
      setIsTargetDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const changeTarget = async (e) => {
    setTarget(e.value);
    setTitle(e.label);
    setSubTarget(null);
    setOptsSubTarget(null);

    setIsSubTargetDisabled(true);
    setIsBtnDisabled(true);

    const params = { tahun: year, target_id: e.value };
    try {
      const result = await getTargetIndicator(params);
      const subTarget = result.map((item) => ({
        value: item.val,
        label: item.label,
      }));

      setOptsSubTarget(subTarget);
      setIsSubTargetDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const changeSubTarget = async (e) => {
    setSubTarget(e.value);
    setSubtitle(e.label);
    setIsBtnDisabled(false);
  };

  const showData = async () => {
    setIsLoadChart(true);
    setDataChart(null);

    const params = {
      tahun: year,
      target_id: target,
      sub_target: subTarget,
    };

    try {
      const result = await getBarChart(params);
      setDataChart(result);
      setTimeout(() => {
        setIsLoadChart(false);
      }, 500);
    } catch (err) {
      console.log(err);
      setIsLoadChart(false);
    }
  };

  useEffect(() => {
    initialBar();
  }, []);

  return (
    <div className="chart-container mt-3 mt-md-5">
      <div className="row">
        <div className="col-12">
          <h4 className="text-center text-uppercase">
            Grafik Perkembangan SDGs DPR di DAERAH PEMILIHAN
          </h4>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 d-flex flex-column flex-md-row justify-content-center align-items-md-end">
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Tahun</Label>
            <Select
              name="year"
              options={mapConfs.optsYear}
              placeholder="Tahun"
              styles={mapConfs.selectStyles}
              onChange={(e) => changeYear(e)}
            />
          </div>
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Indikator</Label>
            <Select
              name="goals"
              options={optsGoals}
              placeholder="Indikator"
              styles={mapConfs.selectStyles}
              isDisabled={isGoalsDisabled}
              onChange={(e) => changeGoals(e)}
            />
          </div>
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Sub Indikator</Label>
            <Select
              name="target"
              options={optsTarget}
              placeholder="Sub Indikator"
              styles={mapConfs.selectStyles}
              isDisabled={isTargetDisabled}
              onChange={(e) => changeTarget(e)}
            />
          </div>
          <div className="d-flex flex-column flex-fill mb-3 mb-md-0">
            <Label className="d-none d-md-block">Kategori</Label>
            <Select
              name="target"
              options={optsSubTarget}
              placeholder="Kategori"
              styles={mapConfs.selectStyles}
              isDisabled={isSubTargetDisabled}
              onChange={(e) => changeSubTarget(e)}
            />
          </div>
          <div className="d-flex flex-column mb-3 mb-md-0">
            <Button
              disabled={isBtnDisabled}
              className="sdg-btn-glow"
              onClick={showData}
            >
              Tampilkan Data
            </Button>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="mb-5" style={{ border: "1px dashed #DDDDDD" }} />
          <h6 className="text-center">{title && title}</h6>
          <h6 className="text-center mb-4">{subtitle && `(${subtitle})`}</h6>
          {isLoadChart && !dataChart && (
            <div className="d-flex justify-content-center">
              <Spinner
                color="info"
                style={{
                  width: "10rem",
                  height: "10rem",
                  marginTop: "10%",
                  marginBottom: "5%",
                }}
              />
            </div>
          )}
          {dataChart && (
            <ReactECharts
              option={dataChart}
              theme="dark"
              showLoading={isLoadChart}
              style={{ height: "1400px" }}
            />
          )}
          <p>
            <small>Sumber Data: BPS</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Bar;
