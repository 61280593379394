import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { fetchCommunityOfPracticeById } from "utils/api/CoPAPI";

import Topbar from "components/layout/Topbar/Topbar";
import Footer from "components/layout/Footer/Footer";
import FrontDetailBreadCrumb from "components/FrontDetailBreadCrumb/FrontDetailBreadCrumb";
import KnowledgeHubCardGroup from "../KnowledgeHub/KnowledgeHubCardGroup";
import SubHeaderSubTitle from "components/SubHeaderTitle/SubHeaderTitle";
import LokalisasiDetailLoader from "components/skeleton/Lokalisasi";

const CommunityOfPracticeDetail = () => {
  const { commId } = useParams();

  const [communityOfPractice, setCommunityOfPractice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { payload } = await fetchCommunityOfPracticeById(commId);
        const formattedFile = payload.community.files.map((file) => ({
          ...file,
          filename: file.filename.slice(0, file.filename.indexOf(".")),
        }));

        const formattedCommunity = {
          ...payload.community,
          files: formattedFile,
        };
        setCommunityOfPractice(formattedCommunity);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err.response.data.message);
      }
    })();
  }, [commId]);

  return (
    <>
      <Topbar />
      <section className="sdg-front-detail-container container">
        <FrontDetailBreadCrumb text="Community Of Practice" />
        {isLoading || !communityOfPractice ? (
          <div className="w-75 mx-auto pl-5 my-3">
            <LokalisasiDetailLoader className="ml-lg-4" />
          </div>
        ) : (
          <>
            <SubHeaderSubTitle
              title={communityOfPractice.judul}
              username={communityOfPractice.creator_username}
              date={communityOfPractice.created_at}
            />
            <div
              className="my-5 sdg-front-paragraph"
              dangerouslySetInnerHTML={{
                __html: communityOfPractice.deskripsi,
              }}
            />
            <div className="mx-auto">
              {/**Replace this with Images */}
              <KnowledgeHubCardGroup datas={communityOfPractice.files} isCoP />
            </div>
          </>
        )}
      </section>
      <Footer />
    </>
  );
};

export default CommunityOfPracticeDetail;
