import Select, { components } from "react-select";

import { ReactComponent as ChevronDown } from "assets/img/ChevronDown.svg";

const CustomSelect = ({ placeholder, options = {}, onChange, value = "" }) => {
  return (
    <Select
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "transparent",
          padding: ".4rem .5rem",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#fff",
          opacity: ".7",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#fff",
        }),
      }}
      components={{
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            <ChevronDown />
          </components.DropdownIndicator>
        ),
        IndicatorSeparator: () => null,
      }}
      placeholder={placeholder ? placeholder : "Select..."}
      onChange={onChange ? onChange : () => {}}
      options={options}
      value={value}
    />
  );
};

export default CustomSelect;
