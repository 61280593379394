import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_API_URL;

const token = Cookies.get("_SDG_TOKEN");

const fetchAllCommunityOfPractices = async (limit) => {
  const res = await axios.get(
    `${baseUrl}/get_all_community${limit ? `?limit=${limit} ` : ""}`
  );

  return res.data;
};

const fetchCommunityOfPracticesByDate = async (start, end) => {
  const url = `${process.env.REACT_APP_API_URL}/get_all_community${
    start ? `?start=${start}` : ""
  }${end ? `&end=${end}` : ""}`;
  const result = await axios.get(url);
  return result.data;
};

const fetchCommunityOfPracticeById = async (commId) => {
  const res = await axios.get(`${baseUrl}/get_community_by_id?id=${commId}`);

  return res.data;
};

const postCommunityOfPractice = async (commData) => {
  const res = await axios.post(`${baseUrl}/create_community`, commData, {
    headers: { token },
  });

  return res.data;
};

const updateCommunityOfPractice = async (commData) => {
  const res = await axios.post(`${baseUrl}/update_community`, commData, {
    headers: { token },
  });

  return res.data;
};

const deleteCommunityOfPractice = async (commId) => {
  const res = await axios.delete(`${baseUrl}/delete_community`, {
    headers: { token },
    data: { id: commId },
  });

  return res.data;
};

export {
  fetchAllCommunityOfPractices,
  fetchCommunityOfPracticesByDate,
  fetchCommunityOfPracticeById,
  postCommunityOfPractice,
  updateCommunityOfPractice,
  deleteCommunityOfPractice,
};
