import { Link } from "react-router-dom";

import "./AdminFooter.scss";

const AdminFooter = () => {
  return (
    <footer className="d-flex justify-content-between sdg-admin-footer">
      <span>Copyright © 2021 SDGs DPR RI</span>
      <div className="">
        <Link to="/manage" className="sdg-admin-footer__link">
          Home
        </Link>
        <Link to="/manage" className="sdg-admin-footer__link">
          Contact Us
        </Link>
        <Link to="/manage" className="sdg-admin-footer__link">
          FAQ
        </Link>
      </div>
    </footer>
  );
};

export default AdminFooter;
