import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as ArrowBack } from "assets/img/ArrowBack.svg";

import ButtonOval from "components/ButtonAdmin/ButtonOval";

import "./AdminTitle.scss";

const AdminTitle = ({
  children,
  viewTitle,
  className: userClassName,
  capitalize,
}) => {
  const { goBack, push } = useHistory();
  const { articleId } = useParams();
  return (
    <header
      className={`d-flex align-items-center my-4 ${
        userClassName ? userClassName : ""
      }`}
    >
      <ArrowBack
        className="mr-3 sdg-admin-manage__back-icon"
        onClick={goBack}
      />
      <h5
        className={`sdg-admin-manage__title mb-0 ${
          capitalize ? "text-capitalize" : ""
        }`}
      >
        {children}
      </h5>
      {viewTitle && (
        <ButtonOval
          className="ml-auto"
          onClick={() =>
            push(`/manage/artikel-informasi/edit-artikel/${articleId}`)
          }
        >
          Edit Berita
        </ButtonOval>
      )}
    </header>
  );
};

export default AdminTitle;
