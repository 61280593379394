import moment from "moment";

const getOptsYear = () => {
  const yearStart = parseInt(moment().format("YYYY")) - 1;
  const yearEnd = yearStart - 10;

  let arr = [];
  let index = 0;
  for (let i = yearStart; i >= yearEnd; i--) {
    arr[index] = i;
    index++;
  }

  return arr.map((item) => ({ label: item, value: item }));
};

const mapStyle = {
  weight: 1,
  opacity: 1,
  color: "black",
  dashArray: "1",
  fillOpacity: 1,
};

const selectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#101D35",
    borderColor: state.isDisabled ? "#a9a9a9" : "#4BC8F5",
    borderRadius: "8px",
    boxShadow: state.isDisabled
      ? "none"
      : "0px 1px 5px rgba(26, 201, 218, 0.56)",
    "&:hover": {
      borderColor: "#4BC8F5",
      boxShadow: "0px 3px 10px rgba(26, 201, 218, 0.56)",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#a9a9a9" : "#4BC8F5",
    "&:hover": { color: "#4BC8F5" },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#a9a9a9" : "#4BC8F5",
  }),
  menuList: (provided, state) => ({ ...provided, backgroundColor: "#000" }),
  indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
  singleValue: (provided) => ({ ...provided, color: "#fff" }),
  container: (provided) => ({ ...provided, flex: 1, marginRight: "1rem" }),
};

const optsYear = getOptsYear();
const optsLevel = [
  {
    label: "Nasional",
    value: "Nasional",
  },
  {
    label: "Provinsi",
    value: "Provinsi",
  },
  {
    label: "Kab/Kota",
    value: "Kab/Kota",
  },
  {
    label: "Dapil",
    value: "Dapil",
  },
];
const optsLevelMap = [
  {
    label: "Provinsi",
    value: "Provinsi",
  },
  {
    label: "Kab/Kota",
    value: "Kab/Kota",
  },
  {
    label: "Dapil",
    value: "Dapil",
  },
];

export { mapStyle, selectStyles, optsLevel, optsLevelMap, optsYear };
