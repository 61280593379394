const KnowledgeHubSection = ({ title, children }) => {
  return (
    <section className="sdg-knowledgehub-container container-fluid">
      <div className="sdg-knowledgehub__title">{title}</div>
      {children}
    </section>
  );
};

export default KnowledgeHubSection;
