import axios from "axios";
import Cookies from "js-cookie";

const read = async () => {
  const url = `${process.env.REACT_APP_API_URL}/get_dapil_all`;
  const result = await axios.get(url);
  return result.data;
};

const create = async (bodyReq) => {
  const headerReq = {
    headers: { token: Cookies.get("_SDG_TOKEN") },
  };
  const url = `${process.env.REACT_APP_API_URL}/create_dapil`;
  const result = await axios.post(url, bodyReq, headerReq);
  return result.data;
};

const rename = async (bodyReq) => {
  const headerReq = {
    headers: { token: Cookies.get("_SDG_TOKEN") },
  };
  const url = `${process.env.REACT_APP_API_URL}/update_dapil`;
  const result = await axios.post(url, bodyReq, headerReq);
  return result.data;
};

const remove = async (id) => {
  const headerReq = {
    headers: { token: Cookies.get("_SDG_TOKEN") },
    data: { id_dapil: id },
  };
  const url = `${process.env.REACT_APP_API_URL}/delete_dapil`;
  const result = await axios.delete(url, headerReq);
  return result.data;
};

export { read, create, rename, remove };
