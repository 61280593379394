import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import "moment/locale/id";

import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/front/main.scss";
import "assets/scss/front/global.scss";
import "assets/scss/admin/global.scss";
import "../node_modules/font-awesome/css/font-awesome.min.css";

moment.locale("id");

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>,
  // </React.StrictMode>,
  document.getElementById("root")
);
