import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Topbar, Footer } from "components/layout";
import ArtikelItem from "components/ArtikelItem";
import HomeArticleLoader from "components/skeleton/Article/HomeArticleLoader";

import { fetchAllArticles } from "utils/api/ArtikelAPI";

import sdImg from "assets/img/sd-img.png";
import sdgBanner from "assets/img/sdg-banner.png";
import homepageDashboard from "assets/img/homepage-dashboard.png";

// import articles from "./article.json";

import "./Home.scss";

const Home = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { payload } = await fetchAllArticles(3);
        setArticles(payload.artikel);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    })();
  }, []);

  return (
    <div>
      <Topbar />
      <section className="sdg-homepage container-fluid">
        <div className="sdg-homepage__welcome row">
          <div className="col-12 col-md-6 sdg-homepage__welcome-text">
            <p>Selamat Datang di Portal</p>
            <p>Monitoring SDGs DPR RI</p>
            <p>
              Dewan Perwakilan Rakyat (DPR RI) berperan mengawasi Pemerintah
              dalam pelaksanaan Tujuan Pembangunan Berkelanjutan (SDGs), baik di
              tingkat nasional maupun daerah. Portal Monitoring ini dikembangkan
              untuk mempermudah Anggota DPR RI dan masyarakat dalam melakukan
              pengawasan dan evaluasi terhadap perkembangan dan capaian–capaian
              SDGs berdasarkan provinsi dan daerah pemilihan.
            </p>
            <Link className="sdg-link-glow" to="/tentang-kami?language=INA">
              LIHAT SELENGKAPNYA
            </Link>
          </div>
          <div className="col-12 col-md-6 sdg-homepage__welcome-logo">
            <img src={sdgBanner} alt="SDG Program" className="img-fluid" />
          </div>
        </div>
        <div className="sdg-homepage__articleInfo row">
          <div className="col-12 sdg-homepage__articleInfo-title">
            <h3>ARTIKEL DAN INFORMASI</h3>
            <div className="sdg-homepage__border-glow"></div>
          </div>
          {isLoading ? (
            <HomeArticleLoader />
          ) : (
            <ArtikelItem articles={articles} />
          )}
          <div className="col-12 text-center">
            <Link className="sdg-link-glow" to="/artikel">
              LIHAT SELENGKAPNYA
            </Link>
          </div>
        </div>
        <div className="sdg-homepage__dashboard row">
          <div className="col-12 col-md-6 sdg-homepage__dashboard-left">
            <img
              src={homepageDashboard}
              alt="Homepage Dashboard"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-6 sdg-homepage__dashboard-right">
            <img src={sdImg} alt="SD Logo" className="img-fluid" />
            <p>Dashboard Monitoring SDGs</p>
            <p>DEWAN PERWAKILAN RAKYAT</p>
            <p>
              Dashboard Monitoring SDGs dikembangkan dalam rangka untuk
              mengevaluasi perkembangan dan pencapaian Agenda Tujuan Pembangunan
              Berkelanjutan di tingkat Daerah Pemilihan (dapil), sehingga dapat
              digunakan sebagai input kebijakan melalui evidence-based policy
              tingkat parlemen dalam mengawasi Pemerintah Indonesia dalam
              Pembangunan Berkelanjutan di Indonesia
            </p>
            <Link className="sdg-link-glow" to="/dashboard">
              LIHAT DASHBOARD
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
