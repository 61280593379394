import { useEffect, useState } from "react";

import { fetchAllArticles } from "utils/api/ArtikelAPI";

import ArtikelItem from "components/ArtikelItem";
import HeaderTitle from "components/HeaderTitle";
import { Footer, Topbar } from "components/layout";
import { HomeArticleLoader } from "components/skeleton/Article";

// import articles from "./article.json";

const Artikel = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { payload } = await fetchAllArticles();
        setArticles(payload.artikel);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    })();
  }, []);

  return (
    <div>
      <Topbar />
      <div className="container-fluid">
        <HeaderTitle title="ARTIKEL & BERITA" />
        <div className="row">
          {isLoading ? (
            <HomeArticleLoader />
          ) : (
            <ArtikelItem articles={articles} />
          )}
          <div className="col-12 text-center">
            <button className="sdg-link-glow" to="/tentang-kami">
              LIHAT LEBIH BANYAK
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Artikel;
