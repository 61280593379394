import moment from "moment";

import "./SubHeaderTitle.scss";

const SubHeaderSubTitle = ({ title, className, username = "", date = "" }) => {
  return (
    <>
      <section className={`sdg-subheadertitle ${className}`}>
        <h2 className="sdg-subheadertitle__subtitle text-center">{title}</h2>
        {username && date && (
          <p className="sdg-subheadertitle__author text-center">
            <i>
              {username} {" • "}
              {moment(date).format("D MMMM YYYY")}
            </i>
          </p>
        )}
      </section>
    </>
  );
};

export default SubHeaderSubTitle;
