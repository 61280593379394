import axios from "axios";
import Cookies from "js-cookie";

const read = async () => {
  const url = `${process.env.REACT_APP_API_URL}/get_all_masukan_publik`;
  const result = await axios.get(url);
  return result.data;
};

const readByDate = async (start, end) => {
  const url = `${process.env.REACT_APP_API_URL}/get_all_masukan_publik${
    start ? `?start=${start}` : ""
  }${end ? `&end=${end}` : ""}`;
  const result = await axios.get(url);
  return result.data;
};

const retrieveById = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/get_masukan_publik_by_id?id=${id}`;
  const result = await axios.get(url);
  return result.data;
};

const create = async (bodyReq) => {
  const url = `${process.env.REACT_APP_API_URL}/create_masukan_publik`;
  const result = await axios.post(url, bodyReq);
  return result.data;
};

const createBalasan = async (bodyReq) => {
  const headerReq = {
    headers: { token: Cookies.get("_SDG_TOKEN") },
  };
  const url = `${process.env.REACT_APP_API_URL}/respon_masukan_publik`;
  const result = await axios.post(url, bodyReq, headerReq);
  return result.data;
};

export { create, read, readByDate, retrieveById, createBalasan };
