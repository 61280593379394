import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import { Form, FormGroup, Label, Input, Row } from "reactstrap";
import { Topbar, Footer } from "components/layout";

import "./Masukan.scss";

// import MasukanHeader from "assets/img/MasukanHeader.png";
import MasukanHeader from "assets/img/MasukanHeader-in.png";

import { makeMasukanMockData } from "utils/helpers";

import MasukanCardGroup from "./MasukanCardGroup";
import CustomSelect from "components/CustomSelect";
import ButtonOutline from "components/ButtonOutline";
import HeaderTitle from "components/HeaderTitle";
import * as DapilAPI from "utils/api/DapilAPI";
import * as MasukanAPI from "utils/api/MasukanAPI";

const MOCK_DATA = makeMasukanMockData(17);

const Masukan = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectionArea, setSelectionArea] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectOptions, setSelectOptions] = useState([
    { value: "val1", label: "Jakarta" },
    { value: "val2", label: "Surabaya" },
  ]);

  useEffect(() => {
    getData();
    setSuccess(false);
  }, []);

  const cleanForm = () => {
    setFullName("");
    setEmail("");
    setPhoneNumber("");
    setSelectionArea("");
    setSuggestion("");
  };

  const handleOpenModal = (id, name, image) => {
    setModalData({ id, name, image });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSuccess(false);
  };

  const handleSelectionChange = (val) => {
    setSelectionArea(val);
  };

  const onDismiss = () => setIsError(false);

  const getData = async () => {
    setIsLoading(true);
    try {
      const result = await DapilAPI.read();
      if (result.status === 200) {
        setTimeout(() => {
          const newData = result.payload.dapil_list.map((itemData) => {
            return {
              value: itemData.id,
              label: itemData.provinsi,
            };
          });
          setSelectOptions(newData);
          setIsLoading(false);
        }, 300);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormAdd = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const bodyReq = {
      nama: fullName,
      email: email,
      dapil_id: selectionArea.value,
      indikator: modalData.id,
      nomor_telepon: phoneNumber,
      masukan: suggestion,
    };

    try {
      const result = await MasukanAPI.create(bodyReq);
      if (result.status === 201) {
        setIsLoading(false);
        setIsError(false);
        setSuccess(true);
        cleanForm();
      }
    } catch (err) {
      setIsError(true);
      setAlert({ status: "danger", msg: err.response.data.message });
      setIsLoading(false);
    }
  };

  const successModal = !!modalData && (
    <Modal
      isOpen={showModal}
      toggle={handleCloseModal}
      className="sdg-masukan-modal-success"
      centered
    >
      <ModalBody className="text-center mt-3">
        <div className="row justify-content-lg-center mb-4 sdg-masukan-modal__header-row">
          <div className="col-12 sdg-masukan-modal__header-text-col">
            <h4 className="sdg-masukan-modal__header-text">Berhasil!</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 p-0 p-lg-2 ml-3 ml-lg-0">
            <p className="sdg-masukan-modal__header-text sdg-masukan-modal__header-text--medium mb-0">
              Data masukan Anda telah berhasil kami terima untuk layanan :
            </p>
          </div>
        </div>
        <Row className="mt-4">
          <div className="col-lg-12">
            <img
              src={modalData.image}
              alt={modalData.name}
              className="sdg-masukan-modal__content-image"
            />
          </div>
        </Row>
        <Row className="mt-3">
          <div className="col-lg-12">
            <p className="sdg-masukan-modal__header-text">{modalData.name}</p>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter className="sdg-masukan-modal__footer-success text-center">
        <div className="sdg-front-card__download-btn">
          <Link to="/">Kembali ke Beranda</Link>
        </div>
      </ModalFooter>
    </Modal>
  );

  const shouldRenderModal = !!modalData && (
    <Modal
      isOpen={showModal}
      toggle={handleCloseModal}
      className="sdg-masukan-modal"
      centered
    >
      <ModalHeader
        className="sdg-masukan-modal__header"
        toggle={handleCloseModal}
      >
        <div className="row justify-content-lg-center mb-4 sdg-masukan-modal__header-row">
          <div className="col-3 d-flex justify-content-end sdg-masukan-modal__header-image-col mb-4 mb-lg-0">
            <div className="sdg-masukan-modal__header-image-container">
              <img
                src={modalData.image}
                alt={modalData.name}
                className="sdg-masukan-modal__header-image"
              />
            </div>
          </div>
          <div className="col-4 sdg-masukan-modal__header-text-col">
            <div className="d-flex flex-column align-items-center align-items-lg-stretch">
              <span className="sdg-masukan-modal__header-text sdg-masukan-modal__header-text--small d-inline-block">
                Form Masukan
              </span>
              <p className="sdg-masukan-modal__header-text">{modalData.name}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 p-0 p-lg-2 ml-3 ml-lg-0">
            <p className="sdg-masukan-modal__header-text sdg-masukan-modal__header-text--medium mb-0">
              Silahkan tulis secara lengkap saran dan masukan terkait dengan
              Tujuan Pembangunan Berkelanjutan atau Sustainable Development
              Goals (SDGs) melalui form dibawah ini :
            </p>
          </div>
        </div>
      </ModalHeader>
      <Form className="sdg-masukan-form" onSubmit={handleFormAdd}>
        <ModalBody>
          <Row form>
            <div className="col-lg-6">
              <FormGroup>
                <Label className="sdg-masukan-form__label">Nama Lengkap</Label>
                <Input
                  type="text"
                  placeholder="Masukkan nama lengkap"
                  className="sdg-masukan-form__input"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </FormGroup>
            </div>
            <div className="col-lg-6">
              <FormGroup>
                <Label className="sdg-masukan-form__label">Email</Label>
                <Input
                  type="email"
                  placeholder="Masukkan email aktif"
                  className="sdg-masukan-form__input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>
            </div>
          </Row>
          <Row form>
            <div className="col-lg-6">
              <FormGroup>
                <Label className="sdg-masukan-form__label">Nomor Telfon</Label>
                <Input
                  type="text"
                  placeholder="628xxxxxxxxx"
                  className="sdg-masukan-form__input"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </FormGroup>
            </div>
            <div className="col-lg-6">
              <FormGroup>
                <Label className="sdg-masukan-form__label">Dapil</Label>
                <CustomSelect
                  placeholder="-Pilih Dapil-"
                  value={selectionArea}
                  onChange={handleSelectionChange}
                  options={selectOptions}
                />
              </FormGroup>
            </div>
          </Row>
          <FormGroup>
            <textarea
              className="form-control sdg-masukan-form__textarea"
              placeholder="Tulis masukan anda"
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="sdg-masukan-modal__footer">
          <div className="sdg-masukan__btn-container mb-lg-4">
            <ButtonOutline onClick={handleCloseModal}>Kembali</ButtonOutline>
            <Button className="sdg-btn-glow sdg-btn-glow--modal">
              {isLoading ? <Spinner color="primary" /> : "KIRIM MASUKAN"}
            </Button>
          </div>
          {!isLoading && isError && (
            <Alert color={alert.status} isOpen={isError} toggle={onDismiss}>
              {alert.msg}
            </Alert>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );

  return (
    <>
      <Topbar />
      <HeaderTitle title="MASUKAN" />
      <section className="sdg-masukan container-fluid pb-xl-5">
        <div className="sdg-masukan__header py-5">
          <img
            src={MasukanHeader}
            alt="Sustainable development goals"
            className="sdg-masukan__header-image"
          />
          <h6 className="sdg-masukan__header-text header-text--small mt-5">
            Pilih dan sampaikan masukan Anda terkait Tujuan Pembangunan
            Berkelanjutan (TPB) atau Sustainable Development Goals (SDGs) disini
            .
          </h6>
        </div>
        {/* <div className="container"> */}
        <MasukanCardGroup items={MOCK_DATA} onItemClick={handleOpenModal} />
        {/* </div> */}
      </section>
      {!success ? shouldRenderModal : successModal}
      <Footer />
    </>
  );
};

export default Masukan;
