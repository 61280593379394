import ContentLoader from "react-content-loader";

const SkeletonFile = (props) => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={125}
    viewBox="0 0 100% 125"
    backgroundColor="#132442"
    foregroundColor="#24B9F2"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="55" height="70" />
    <rect x="0" y="80" rx="3" ry="3" width="150" height="10" />
    <rect x="0" y="100" rx="3" ry="3" width="150" height="10" />
  </ContentLoader>
);

export default SkeletonFile;
