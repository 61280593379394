import { Link, useLocation } from "react-router-dom";
import moment from "moment";

import { stripHtmlTags } from "utils/helpers";

import "./ArtikelItem.scss";

const ArtikelItem = (props) => {
  const { pathname } = useLocation();

  return (
    <div className="sdg-article-item__article row w-100">
      {props.articles.map((item, index) => (
        <div
          key={item.id}
          className="sdg-article-item__article-list col-lg-4 col-12"
        >
          {!item.file_dir ? (
            <div className="sdg-article-item__article-image">
              <img src={`/assets/img/dummy/${item.image}`} alt="Article" />
            </div>
          ) : (
            pathname.includes("/artikel") &&
            index <= 5 && (
              <div className="sdg-article-item__article-image">
                <img src={item.file_dir} alt={item.judul} />
              </div>
            )
          )}
          <div className="sdg-article-item__article-type">
            {item.indikators.map((data, index) => (
              <img
                key={index}
                src={`/assets/img/sdg-program/${data}.svg`}
                alt={data}
              />
            ))}
          </div>
          <h2 className="sdg-article-item__article-title">{item.judul}</h2>
          <p className="sdg-article-item__article-text">
            {stripHtmlTags(item.body)}
          </p>
          <div className="sdg-article-item__article-footer">
            <Link
              to={`artikel/${item.id}`}
              className="sdg-article-item__article-link"
            >
              {" "}
              <span>Selengkapnya</span>
            </Link>
            <span>{moment(item.created_at).format("D-MM-YYYY")}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArtikelItem;
