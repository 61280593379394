import "./Legend.scss";

const Legend = ({ limits, colors }) => {
  const items = [];
  for (let i = 0; i < colors.length; i++) {
    items.push(
      <i key={i} style={{ "--color": colors[i] }}>
        {i === 0 && `${Number(limits[i + 1].toFixed(2))}`}
        {i > 0 &&
          `${Number(Number(limits[i].toFixed(2)) + 0.01).toFixed(2)} - ${Number(
            limits[i + 1]
          ).toFixed(2)}`}
      </i>
    );
  }

  return (
    <div className="legend">
      <div className="text-center font-weight-bold mb-2">Legend</div>
      {items}
    </div>
  );
};

export default Legend;
