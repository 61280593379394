import { useEffect, useState } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Link, Switch, Route } from "react-router-dom";

import AdminDataTable from "components/AdminDataTable";
import AdminContentHeader from "components/layout/AdminLayout/AdminContentHeader";
import AddMasukan from "./AddMasukan";
import { useCheckLogin } from "utils/hooks";

import * as MasukanAPI from "utils/api/MasukanAPI";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const Masukan = () => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  useCheckLogin();

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getData();
  }, [location]);

  const columns = [
    {
      key: "id",
      text: "No.",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
      width: "70px",
      cell: (record, index) => {
        return index + 1;
      },
    },
    {
      key: "nama",
      text: "Nama",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
    },
    {
      key: "email",
      text: "Email",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
    },
    {
      key: "nama_dapil",
      text: "Dapil",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
    },
    {
      key: "indikator",
      text: "Indikator",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
    },
    {
      key: "created_at",
      text: "Date",
      sortable: true,
      width: "175px",
      TrOnlyClassName: "sdg-datatable__table-head",
      cell: (record) => {
        return moment(record.created_at).format("DD/MM/YYYY");
      },
    },
    {
      key: "status",
      text: "Status",
      sortable: true,
      TrOnlyClassName: "sdg-datatable__table-head",
      cell: (record) => {
        return (
          <span
            className={
              record.status === "Masuk"
                ? "sdg-datatable__content-red"
                : "sdg-datatable__content-green"
            }
          >
            {record.status}
          </span>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      width: "125px",
      cell: (record, index) => {
        return (
          <>
            <div className="sdg-datatable__button-group">
              <Link to={`${url}/view/${record.id}`}>
                <button className="sdg-datatable__button-detail">
                  <AiOutlineEye color="#165FBB" size="20" />
                </button>
              </Link>
            </div>
          </>
        );
      },
    },
  ];

  const getData = async () => {
    setIsLoading(true);
    try {
      const result = await MasukanAPI.read();
      if (result.status === 200) {
        setTimeout(() => {
          setData(result.payload.masukan);
          setIsLoading(false);
        }, 300);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(true);
      setError(err.response.data.message);
    }
  };

  const filter = async () => {
    const start = startDate && moment(startDate).format("YYYY-MM-DD");
    const end = startDate && moment(endDate).format("YYYY-MM-DD");

    try {
      setIsLoading(true);
      const result = await MasukanAPI.readByDate(start, end);
      setData(result.payload.masukan);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <Switch>
      <Route exact path={path}>
        <section className="sdg-admin-masukan sdg-admin-manage">
          <AdminContentHeader
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
            title="DATA MASUKAN"
            btnTo={`${url}/add`}
            error={error && error}
            clickFilter={filter}
          />
          {error ? (
            <AdminDataTable
              columns={columns}
              data={data}
              loading={isLoading}
              error={error}
            />
          ) : (
            <AdminDataTable columns={columns} data={data} loading={isLoading} />
          )}
        </section>
      </Route>
      <Route path={`${path}/view/:id`}>
        <AddMasukan />
      </Route>
    </Switch>
  );
};

export default Masukan;
