import axios from "axios";
import Cookies from "js-cookie";

const create = async (bodyReq) => {
  const headerReq = {
    headers: { token: Cookies.get("_SDG_TOKEN") },
  };
  const url = `${process.env.REACT_APP_API_URL}/create_lokalisasi`;
  const result = await axios.post(url, bodyReq, headerReq);
  return result.data;
};

const read = async () => {
  const url = `${process.env.REACT_APP_API_URL}/get_all_lokalisasi`;
  const result = await axios.get(url);
  return result.data;
};

const readByDate = async (start, end) => {
  const url = `${process.env.REACT_APP_API_URL}/get_all_lokalisasi${
    start ? `?start=${start}` : ""
  }${end ? `&end=${end}` : ""}`;
  const result = await axios.get(url);
  return result.data;
};

const getByLimit = async (limit) => {
  const url = `${process.env.REACT_APP_API_URL}/get_all_lokalisasi?limit=${limit}`;
  const result = await axios.get(url);
  return result.data;
};

const retrieveById = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/get_lokalisasi_by_id?id=${id}`;
  const result = await axios.get(url);
  return result.data;
};

const update = async (bodyReq) => {
  const headerReq = {
    headers: { token: Cookies.get("_SDG_TOKEN") },
  };
  const url = `${process.env.REACT_APP_API_URL}/update_lokalisasi`;
  const result = await axios.post(url, bodyReq, headerReq);
  return result.data;
};

const remove = async (id) => {
  const config = {
    headers: { token: Cookies.get("_SDG_TOKEN") },
    data: { id },
  };
  const url = `${process.env.REACT_APP_API_URL}/delete_lokalisasi`;
  const result = await axios.delete(url, config);
  return result.data;
};

export { create, read, readByDate, getByLimit, retrieveById, update, remove };
