import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./FaqForm.scss";
import * as KomunikasiPublikAPI from "utils/api/KomunikasiPublikAPI";
import successIcon from "assets/img/Success-icon.svg";

const FaqForm = () => {
  const [showModal, setShowModal] = useState(false);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [needs, setNeeds] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState(null);
  const [success, setSuccess] = useState(false);

  const onDismiss = () => setIsError(false);

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const bodyReq = {
      nama: name,
      email: email,
      keperluan: needs,
      subjek: subject,
      nomor_telepon: phoneNumber,
      masukan: message,
    };

    try {
      const result = await KomunikasiPublikAPI.create(bodyReq);
      if (result.status === 200) {
        setIsLoading(false);
        setIsError(false);
        setSuccess(true);
        handleOpenModal();
      }
    } catch (err) {
      setIsError(true);
      setAlert({ status: "danger", msg: err.response.data.message });
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSuccess(false);
    cleanForm();
  };

  const cleanForm = () => {
    setName("");
    setEmail("");
    setPhoneNumber("");
    setNeeds("");
    setSubject("");
    setMessage("");
  };

  const inputPhoneNumber = (e) => {
    e.preventDefault();
    let str = e.target.value;
    str = str.replace(/\D/g, "");
    setPhoneNumber(str);
  };
  const successModal = success && (
    <Modal
      isOpen={showModal}
      toggle={handleCloseModal}
      className="sdg-masukan-modal-success"
      centered
    >
      <ModalBody className="text-center mt-3">
        <div className="row justify-content-lg-center mb-4 sdg-masukan-modal__header-row">
          <div className="col-12 sdg-masukan-modal__header-text-col">
            <h4 className="sdg-masukan-modal__header-text">Berhasil!</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 p-0 p-lg-2 ml-3 ml-lg-0">
            <p className="sdg-masukan-modal__header-text sdg-masukan-modal__header-text--medium mb-0">
              Data untuk layanan Konsultasi Publik Anda telah berhasil kami
              terima. Atas Nama :
            </p>
          </div>
        </div>
        <Row className="mt-4">
          <div className="col-lg-12">
            <img
              src={successIcon}
              alt="sdg-success"
              className="sdg-masukan-modal__content-image"
            />
          </div>
        </Row>
        <Row className="mt-3">
          <div className="col-lg-12">
            <p className="sdg-masukan-modal__header-text-success-name">
              {name}
            </p>
            <p className="sdg-masukan-modal__header-text-success-email">
              {email}
            </p>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter className="sdg-masukan-modal__footer-success text-center">
        <div className="sdg-front-card__download-btn">
          <Link to="/">Kembali ke Beranda</Link>
        </div>
      </ModalFooter>
    </Modal>
  );

  return (
    <section className="sdg-komunikasi__form">
      <p>
        Badan Kerja Sama Antar Parlemen (BKSAP) - DPR RI membuka kesempatan
        untuk konsultasi Publik secara langsung di Kantor DPR RI. Silahkan
        mengisi formulir yang ada di bawah untuk menjadwalkan pertemuan dengan
        Staff Sekretariat SDGs DPR RI.
      </p>
      <Form onSubmit={(e) => handleForm(e)} className="mt-5">
        <Row form className="mb-2">
          <Col md={6} className="pr-lg-4">
            <FormGroup>
              <Label for="namaLengkap">Nama Lengkap</Label>
              <Input
                type="text"
                className="py-4 pl-3"
                id="namaLengkap"
                placeholder="Masukkan Nama Lengkap"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                className="py-4 pl-3"
                id="email"
                placeholder="Masukkan Email Aktif"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form className="mb-2">
          <Col md={6} className="pr-lg-4">
            <FormGroup>
              <Label for="keperluan">Keperluan</Label>
              <Input
                type="text"
                className="py-4 pl-3"
                id="keperluan"
                placeholder="Masukkan Keperluan Anda"
                onChange={(e) => setNeeds(e.target.value)}
                value={needs}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="nomorTelfon">Nomor Telfon</Label>
              <Input
                type="tel"
                pattern="[0-9]{11,15}"
                maxLength="15"
                className="py-4 pl-3"
                id="nomorTelfon"
                placeholder="Masukkan Nomor Telfon Aktif"
                value={phoneNumber}
                onChange={inputPhoneNumber}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className="mb-5">
          <Label for="subjek">Subjek</Label>
          <Input
            type="text"
            className="py-4 pl-3"
            id="subjek"
            placeholder="Masukkan Subjek"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            required
          />
          <Input
            type="textarea"
            className="mt-3"
            rows="6"
            id="pesan"
            placeholder="Masukkan Pesan"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required
          />
        </FormGroup>
        <Row form>
          <Col md={12} className="text-center">
            {isLoading ? (
              <button className="sdg-btn-glow px-5 py-2" disabled>
                <Spinner color="primary" />
              </button>
            ) : (
              <button className="sdg-btn-glow px-5 py-2">KIRIM MASUKAN</button>
            )}
          </Col>
          {!isLoading && isError && (
            <Col md={12} className="text-center">
              <Alert color={alert.status} isOpen={isError} toggle={onDismiss}>
                {alert.msg}
              </Alert>
            </Col>
          )}
        </Row>
      </Form>
      {successModal}
    </section>
  );
};

export default FaqForm;
