import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import * as LokalisasiAPI from "utils/api/LokalisasiAPI";
import LokalisasiDetailLoader from "components/skeleton/Lokalisasi";
import Topbar from "components/layout/Topbar/Topbar";
import Footer from "components/layout/Footer/Footer";
import FrontDetailBreadCrumb from "components/FrontDetailBreadCrumb/FrontDetailBreadCrumb";
import SubHeaderSubTitle from "components/SubHeaderTitle/SubHeaderTitle";

const LokalisasiDetail = () => {
  const { id } = useParams();
  const [isLoad, setIsLoad] = useState(true);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState("");
  const getData = async (id) => {
    setIsLoad(true);
    try {
      const result = await LokalisasiAPI.retrieveById(id);
      if (result.status === 200) {
        console.log(result.payload.lokalisasi);
        const { lokalisasi } = result.payload;
        setData(lokalisasi);
        setTitle(lokalisasi.judul);
      }

      setIsLoad(false);
    } catch (err) {
      console.log(err);
      setIsLoad(false);
    }
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  return (
    <div>
      <Topbar />
      {isLoad ? (
        <section className="sdg-front-detail-container container">
          <div className="w-75 mx-auto">
            <LokalisasiDetailLoader className="ml-lg-4" />
          </div>
        </section>
      ) : (
        <section className="sdg-front-detail-container container">
          <FrontDetailBreadCrumb text="MELOKALKAN SDGs" />
          <SubHeaderSubTitle
            title={title}
            username={data.creator_username}
            date={data.created_at}
          />
          <div className="my-5 sdg-front-paragraph">{data.deskripsi}</div>
          <div className="row">
            {data.files.map((data) => (
              <div className="col-12 col-md-6">
                <img
                  key={data.filename}
                  src={data.file_dir}
                  alt={data.filename}
                  className="img-fluid"
                />
              </div>
            ))}
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
};

export default LokalisasiDetail;
