import { useState } from "react";
import { Form, FormGroup, Input, Label, Spinner, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { AiOutlineEyeInvisible as EyeSlashed } from "react-icons/ai";

import { createUser } from "utils/api/UserAPI";
import { checkEmail } from "utils/helpers/";

import Manage from "components/Manage";
import AdminTitle from "components/AdminTitle";
import ButtonOval from "components/ButtonAdmin/ButtonOval";

import { ReactComponent as Eye } from "assets/img/Eye.svg";

import "./AddNewAdmin.scss";

const options = [
  { value: true, label: "Aktif" },
  { value: false, label: "Nonaktif" },
];

const AddNewAdmin = ({ onAdd }) => {
  const { goBack, replace } = useHistory();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [status, setStatus] = useState({ value: true, label: "Aktif" });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isValidPassword = () => password === confirmPassword;
  const isValidEmail = () => checkEmail(email);

  const isValid =
    isValidPassword() &&
    isValidEmail() &&
    email &&
    password &&
    username &&
    confirmPassword;

  const handleFormSubmit = async () => {
    const userData = {
      username,
      email,
      password,
    };

    try {
      setIsLoading(true);
      await createUser(userData);
      setIsLoading(false);
      onAdd();
      replace("/manage/admin");
    } catch (err) {
      setIsLoading(false);
      setError(err.response.data.message);
    }
  };

  const handleEmailInput = (e) => {
    const inputtedEmail = e.target.value;
    setEmail(inputtedEmail);
    isValidEmail();
  };

  const handlePasswordInput = (e) => {
    const inputtedPassword = e.target.value;
    setPassword(inputtedPassword);
    isValidPassword();
  };

  return (
    <>
      <AdminTitle>Tambah Admin</AdminTitle>
      <Manage>
        {error && (
          <Alert color="danger" toggle={() => setError("")}>
            {error}
          </Alert>
        )}
        <Manage.Body>
          <Form>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Email *</Label>
              <Input
                className={`sdg-admin-manage__input ${
                  !isValidEmail() &&
                  isEmailTouched &&
                  "sdg-admin-manage__input--error"
                }`}
                placeholder="Masukkan Email Admin"
                value={email}
                onChange={handleEmailInput}
                onBlur={() => setIsEmailTouched(true)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Username *</Label>
              <Input
                className="sdg-admin-manage__input"
                placeholder="Masukkan Username Admin"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Password *</Label>
              <Input
                className={`sdg-admin-manage__input sdg-admin-manage__input--password ${
                  !isValidPassword() &&
                  isPasswordTouched &&
                  "sdg-admin-manage__input--error"
                }`}
                placeholder="************"
                value={password}
                onChange={handlePasswordInput}
                onBlur={() => setIsPasswordTouched(true)}
                type={showPassword ? "text" : "password"}
              />
              {showPassword ? (
                <EyeSlashed
                  className="sdg-admin-manage__eye-icon sdg-admin-manage__eye-icon--slashed"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : (
                <Eye
                  className="sdg-admin-manage__eye-icon"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">
                Ulangi Password *
              </Label>
              <Input
                className={`sdg-admin-manage__input sdg-admin-manage__input--password ${
                  !isValidPassword() &&
                  isPasswordTouched &&
                  "sdg-admin-manage__input--error"
                }`}
                placeholder="************"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showConfirmPassword ? "text" : "password"}
              />
              {showConfirmPassword ? (
                <EyeSlashed
                  className="sdg-admin-manage__eye-icon sdg-admin-manage__eye-icon--slashed"
                  onClick={() =>
                    setShowConfirmPassword((prevState) => !prevState)
                  }
                />
              ) : (
                <Eye
                  className="sdg-admin-manage__eye-icon"
                  onClick={() =>
                    setShowConfirmPassword((prevState) => !prevState)
                  }
                />
              )}
            </FormGroup>
            <FormGroup>
              <Label className="sdg-admin-manage__label">Status *</Label>
              <Select
                value={status}
                onChange={(value) => setStatus(value)}
                options={options}
              />
            </FormGroup>
          </Form>
        </Manage.Body>
        <Manage.Footer>
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a onClick={goBack} className="sdg-admin-btn sdg-admin-btn--cancel">
            Cancel
          </a>
          {isLoading ? (
            <Spinner color="danger" />
          ) : (
            <ButtonOval onClick={handleFormSubmit} disabled={!isValid}>
              Save
            </ButtonOval>
          )}
        </Manage.Footer>
      </Manage>
    </>
  );
};

export default AddNewAdmin;
