import { Card, CardBody } from "reactstrap";

const ManageBody = ({ children, className: userClassName }) => {
  return (
    <Card className={`w-100 ${userClassName ? userClassName : ""}`}>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default ManageBody;
