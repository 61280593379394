import { Navbar, NavbarBrand } from "reactstrap";
import { UncontrolledDropdown, DropdownToggle } from "reactstrap";
import { DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

import { ReactComponent as Burger } from "assets/img/Burger.svg";

import "./AdminHeader.scss";

import AdminMan from "assets/img/adminMan.jpg";
import logoDpr from "assets/img/logo-dpr-ri.png";

const AdminHeader = ({ user }) => {
  return (
    <Navbar color="light" className="py-0 pl-0">
      <NavbarBrand className="sdg-admin-header__brand">
        <img
          src={logoDpr}
          alt="Logo DPR RI"
          className="sdg-admin-header__logo"
        />
      </NavbarBrand>
      <div className="sdg-admin-header__sidebar-toggler">
        <Burger />
      </div>
      <div className="ml-auto d-flex align-items-center">
        <img
          src={AdminMan}
          alt="admin"
          className="sdg-admin-header__image rounded-circle"
        />
        <UncontrolledDropdown nav>
          <DropdownToggle nav caret className="sdg-admin-header__dropdown pl-0">
            {user && user.username}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <Link to="/logout">Logout</Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Navbar>
  );
};

export default AdminHeader;
