import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_API_URL;

const fetchUserByUsername = async (username) => {
  const res = await axios.get(
    `${baseUrl}/get_user_from_username?username=${username}`
  );

  return res.data;
};

const fetchAllUsers = async () => {
  const res = await axios.get(`${baseUrl}/get_user`);

  return res.data;
};

const createUser = async (userData) => {
  const token = Cookies.get("_SDG_TOKEN");
  const res = await axios.post(`${baseUrl}/create_user`, userData, {
    headers: { token },
  });

  return res.data;
};

const deleteUser = async (username) => {
  const token = Cookies.get("_SDG_TOKEN");
  const res = await axios.delete(`${baseUrl}/delete_user`, {
    headers: { token },
    data: { username },
  });

  return res.data;
};

const updateUser = async (reqBody) => {
  const token = Cookies.get("_SDG_TOKEN");
  const res = await axios.post(`${baseUrl}/update_user`, reqBody, {
    headers: { token },
  });

  return res.data;
};

export {
  fetchUserByUsername,
  fetchAllUsers,
  createUser,
  deleteUser,
  updateUser,
};
