import { NavLink, useLocation } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

import "./AdminSidebar.scss";

import { getUserName } from "utils/helpers/localStorageHelpers";
import { ReactComponent as Pencil } from "assets/img/Pencil.svg";
import { ReactComponent as Document } from "assets/img/Document.svg";
import { ReactComponent as Stamper } from "assets/img/Stamper.svg";
import { ReactComponent as MapMarker } from "assets/img/MapMarker.svg";
import { ReactComponent as Clipboard } from "assets/img/Clipboard.svg";
import { ReactComponent as Chat } from "assets/img/Chat.svg";
import { ReactComponent as RadiusMarker } from "assets/img/RadiusMarker.svg";
import { ReactComponent as UserCircle } from "assets/img/UserCircle.svg";

const AdminSidebar = () => {
  const { pathname } = useLocation();
  const userName = getUserName();
  return (
    <Nav vertical className="sdg-admin-sidebar">
      <NavItem className="sdg-admin-sidebar__nav-item">
        <NavLink
          to="/manage/artikel-informasi"
          className="sdg-admin-sidebar__nav-link"
          activeClassName="sdg-admin-sidebar__nav-link--active"
          isActive={() => pathname.includes("artikel-informasi")}
        >
          <Pencil className="sdg-admin-sidebar__icon" /> Artikel Informasi
        </NavLink>
      </NavItem>
      <NavItem className="sdg-admin-sidebar__nav-item">
        <NavLink
          to="/manage/dokumen"
          className="sdg-admin-sidebar__nav-link"
          activeClassName="sdg-admin-sidebar__nav-link--active"
          isActive={() => pathname.includes("dokumen")}
        >
          <Document className="sdg-admin-sidebar__icon" /> Dokumen
        </NavLink>
      </NavItem>
      <NavItem className="sdg-admin-sidebar__nav-item">
        <NavLink
          to="/manage/regulasi"
          className="sdg-admin-sidebar__nav-link"
          activeClassName="sdg-admin-sidebar__nav-link--active"
          isActive={() => pathname.includes("regulasi")}
        >
          <Stamper className="sdg-admin-sidebar__icon" /> Regulasi
        </NavLink>
      </NavItem>
      <NavItem className="sdg-admin-sidebar__nav-item">
        <NavLink
          to="/manage/lokalisasi"
          className="sdg-admin-sidebar__nav-link"
          activeClassName="sdg-admin-sidebar__nav-link--active"
          isActive={() => pathname.includes("lokalisasi")}
        >
          <MapMarker className="sdg-admin-sidebar__icon" /> Lokalisasi SDGs
        </NavLink>
      </NavItem>
      <NavItem className="sdg-admin-sidebar__nav-item">
        <NavLink
          to="/manage/community-of-practice"
          className="sdg-admin-sidebar__nav-link"
          activeClassName="sdg-admin-sidebar__nav-link--active"
          isActive={() => pathname.includes("community")}
        >
          <Clipboard className="sdg-admin-sidebar__icon" /> Community of
          Practice
        </NavLink>
      </NavItem>
      <NavItem className="sdg-admin-sidebar__nav-item">
        <NavLink
          to="/manage/masukan"
          className="sdg-admin-sidebar__nav-link"
          activeClassName="sdg-admin-sidebar__nav-link--active"
          isActive={() => pathname.includes("masukan")}
        >
          <Chat className="sdg-admin-sidebar__icon" /> Masukan
        </NavLink>
      </NavItem>
      <NavItem className="sdg-admin-sidebar__nav-item">
        <NavLink
          to="/manage/komunikasi-publik"
          className="sdg-admin-sidebar__nav-link"
          activeClassName="sdg-admin-sidebar__nav-link--active"
          isActive={() => pathname.includes("komunikasi-publik")}
        >
          <Chat className="sdg-admin-sidebar__icon" /> Komunikasi Publik
        </NavLink>
      </NavItem>
      {/**Separator */}
      <NavItem>
        <div className="sdg-admin-sidebar__separator">Master Data</div>
      </NavItem>
      <NavItem className="sdg-admin-sidebar__nav-item">
        <NavLink
          to="/manage/daerah-pemilihan"
          className="sdg-admin-sidebar__nav-link"
          activeClassName="sdg-admin-sidebar__nav-link--active"
          isActive={() => pathname.includes("daerah-pemilihan")}
        >
          <RadiusMarker className="sdg-admin-sidebar__icon" /> Daerah Pemilihan
        </NavLink>
      </NavItem>
      {userName === "admin" && (
        <NavItem className="sdg-admin-sidebar__nav-item">
          <NavLink
            to="/manage/admin"
            className="sdg-admin-sidebar__nav-link"
            activeClassName="sdg-admin-sidebar__nav-link--active"
            isActive={() => pathname.includes("admin")}
          >
            <UserCircle className="sdg-admin-sidebar__icon" /> Admin
          </NavLink>
        </NavItem>
      )}
    </Nav>
  );
};

export default AdminSidebar;
