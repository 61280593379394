import axios from "axios";
import Cookies from "js-cookie";

const { REACT_APP_API_URL: baseUrl } = process.env;

const token = Cookies.get("_SDG_TOKEN");

const fetchAllArticles = async (limit) => {
  const res = await axios.get(`
    ${baseUrl}/get_all_artikel${limit ? `?limit=${limit}` : ""}`);
  return res.data;
};

const fetchArticlesByDate = async (start, end) => {
  const res = await axios.get(`
    ${baseUrl}/get_all_artikel${start ? `?start=${start}` : ""}${
    end ? `&end=${end}` : ""
  }`);
  return res.data;
};

const fetchArticleById = async (articleId) => {
  const res = await axios.get(`${baseUrl}/get_artikel_by_id?id=${articleId}`);

  return res.data;
};

const postArticle = async (articleData) => {
  const config = {
    headers: {
      token,
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await axios.post(
    `${baseUrl}/create_artikel`,
    articleData,
    config
  );

  return res.data;
};

const putArticle = async (articleData) => {
  const config = {
    headers: {
      token,
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await axios.post(
    `${baseUrl}/update_artikel`,
    articleData,
    config
  );

  return res.data;
};

const deleteArticle = async (articleId) => {
  const config = { headers: { token }, data: { id: articleId } };
  const res = await axios.delete(`${baseUrl}/delete_artikel`, config);

  return res.data;
};

export {
  fetchAllArticles,
  fetchArticlesByDate,
  fetchArticleById,
  postArticle,
  putArticle,
  deleteArticle,
};
