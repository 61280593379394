import KnowledgeHubCardItem from "./KnowledgeHubCardItem";

const KnowledgeHubCardGroup = ({
  datas,
  path = null,
  detail = null,
  isCoP,
  isCoPView,
}) => {
  return (
    <div className="sdg-knowledgehub__content row">
      {datas.map(({ id, ...props }) => (
        <KnowledgeHubCardItem
          key={id}
          id={id}
          path={path + id}
          detail={detail}
          isCoP={isCoP}
          isCoPView={isCoPView}
          {...props}
        />
      ))}
    </div>
  );
};

export default KnowledgeHubCardGroup;
